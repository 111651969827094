import { action, makeObservable, observable } from 'mobx'

interface IGiftRegistry {
    id: string,
    name: string,
    external_url: string
}

class GiftRegistriesIndexModel {
  giftRegistries: IGiftRegistry[]
  serverPath: string
  absoluteUrlRegex: RegExp
  isUrlErrorActive: boolean
  newRegistry: IGiftRegistry
  tempRegistry: IGiftRegistry
  isRegistryEditing: boolean
  isLoading: boolean

  constructor (initialRegistries: any, serverPath: string) {
    this.giftRegistries = initialRegistries
    this.serverPath = serverPath
    this.absoluteUrlRegex = /https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/i
    this.isUrlErrorActive = false
    this.resetNewRegistry()
    this.tempRegistry = { id: '', name: '', external_url: '' }
    this.isRegistryEditing = false
    this.isLoading = false

    makeObservable(this, {
      giftRegistries: observable,
      newRegistry: observable,
      tempRegistry: observable,
      isRegistryEditing: observable,
      isLoading: observable,
      isUrlErrorActive: observable,
      setIsLoading: action,
      setIsUrlErrorActive: action,
      sendGiftRegistryToServer: action,
      addGiftRegistry: action,
      removeGiftRegistry: action,
      updateGiftRegistry: action,
      setRegistryEditing: action,
      setNewRegistryName: action,
      setNewRegistryExternalUrl: action,
      resetNewRegistry: action,
      removeGiftRegistryFromServer: action,
      updateGiftRegistryOnServer: action
    })
  }

  setIsLoading (loading: boolean) {
    this.isLoading = loading
  }

  setIsUrlErrorActive (isActive: boolean) {
    this.isUrlErrorActive = isActive
  }

  setRegistryEditing (isEditing: boolean) {
    this.isRegistryEditing = isEditing
  }

  setTempRegistryName (name: string) {
    this.tempRegistry.name = name
  }

  setTempRegistryURL (url: string) {
    this.tempRegistry.external_url = url
  }

  setTempRegistryId (id: string) {
    this.tempRegistry.id = id
  }

  resetTempRegistry () {
    this.tempRegistry = {
      name: '',
      external_url: '',
      id: ''
    }
  }

  setNewRegistryName (newName: string) {
    this.newRegistry.name = newName
  }

  setNewRegistryExternalUrl (newUrl: string) {
    this.newRegistry.external_url = newUrl
  }

  resetNewRegistry () {
    this.newRegistry = {
      name: '',
      external_url: '',
      id: ''
    }
  }

  addGiftRegistry (hydratedGiftRegistry: IGiftRegistry) {
    this.giftRegistries.push(hydratedGiftRegistry)
  }

  removeGiftRegistry (deletedId: string) {
    this.giftRegistries = this.giftRegistries.filter((element) => {
      return element.id != deletedId
    })
  }

  updateGiftRegistry (updateRegistry: IGiftRegistry) {
    this.giftRegistries = this.giftRegistries.map((element) => {
      if (element.id == updateRegistry.id) {
        return updateRegistry
      } else {
        return element
      }
    })
  }

  sendGiftRegistryToServer () {
    const csrfToken = document.querySelector("[name='csrf-token']")?.content
    this.setIsLoading(true)
    const that = this
    return fetch(this.serverPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'gift-registry': {
          name: this.newRegistry.name,
          external_url: this.newRegistry.external_url
        }
      })
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error: Something went wrong')
      }
    }).then(newGiftRegistryResponse => {
      that.addGiftRegistry(newGiftRegistryResponse['gift-registry'])
      that.resetNewRegistry()
      this.setIsLoading(false)
    })
  }

  removeGiftRegistryFromServer (deletedId: string) {
    const csrfToken = document.querySelector("[name='csrf-token']")?.content
    this.setIsLoading(true)
    const that = this
    return fetch(this.serverPath + `/${deletedId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error: Something went wrong')
      }
    }).then(deleteRegistryResponse => {
      that.removeGiftRegistry(deleteRegistryResponse['gift-registry'].id)
      this.setIsLoading(false)
    })
  }

  updateGiftRegistryOnServer (updatedRegistry: IGiftRegistry) {
    const csrfToken = document.querySelector("[name='csrf-token']")?.content
    this.setIsLoading(true)
    const that = this
    return fetch(this.serverPath + `/${updatedRegistry.id}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'gift-registry': {
          name: updatedRegistry.name,
          external_url: updatedRegistry.external_url
        }
      })
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error: Something went wrong')
      }
    }).then(updatedGiftRegistryResponse => {
      that.updateGiftRegistry(updatedGiftRegistryResponse['gift-registry'])
      this.setIsLoading(false)
    })
  }
}

export default GiftRegistriesIndexModel
