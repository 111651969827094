import { makeObservable, observable, action, computed } from 'mobx'
import { events_path } from '../../../../routes.js.erb'

interface IEventSimpleNewModel {
  brand_name: string,
  starterApp: string,
  twilioWaFirst: boolean
}

class EventSimpleNewModel {
  eventName: string
  where: string
  when: string
  additionalInfo: string
  brandName: string
  stepNumber: number
  clicked: boolean
  isInputEmpty: boolean
  starterApp: string
  twilioWaFirst: boolean

  constructor ({ brand_name, starterApp, twilioWaFirst }: IEventSimpleNewModel) {
    this.eventName = ''
    this.stepNumber = 1
    this.where = ''
    this.when = ''
    this.additionalInfo = ''
    this.brandName = brand_name
    this.starterApp = starterApp
    this.twilioWaFirst = twilioWaFirst
    this.clicked = false
    this.isInputEmpty = false

    makeObservable(this, {
      eventName: observable,
      stepNumber: observable,
      where: observable,
      when: observable,
      additionalInfo: observable,
      clicked: observable,
      isInputEmpty: observable,
      setEventName: action,
      setStepNumber: action,
      setWhere: action,
      setWhen: action,
      setIsInputEmpty: action,
      serverPayload: computed
    })
  }

  setEventName (newEventName: string) {
    this.eventName = newEventName
  }

  setStepNumber (newStepNumber: number) {
    if (ahoy) {
      ahoy.track('EventSimpleNewApp setStepNumber ' + newStepNumber)
    }
    this.stepNumber = newStepNumber
  }

  setWhere (newWhere: string) {
    this.where = newWhere
  }

  setWhen (newWhen: string) {
    this.when = newWhen
  }

  setAdditionalInfo (newInfo: string) {
    this.additionalInfo = newInfo
  }

  setClicked (newClicked: boolean) {
    this.clicked = newClicked
  }

  setIsInputEmpty (isEmpty: boolean) {
    this.isInputEmpty = isEmpty
  }

  public get serverPayload () {
    const data = new FormData()

    const clientData = {
      name: this.eventName,
      location: this.where,
      when: this.when,
      additionalInfo: this.additionalInfo,
      starterApp: this.starterApp,
      twilioWaFirst: this.twilioWaFirst
    }
    data.append(
      'client_data',
      JSON.stringify(clientData)
    )

    return data
  }

  sendDataToServer () {
    if (ahoy) {
      ahoy.track('EventSimpleNewApp click save')
    }

    const csrfToken = document.querySelector("[name='csrf-token']")?.content
    const that = this
    fetch(events_path(LOCALE), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken
      },
      body: this.serverPayload
    }).then((response) => {
      if (response.redirected) {
        return { then: that.followRedirect(response) }
      } else {
        return { then: response.json().then(that.redirectToServerPage.bind(that)) }
      }
    })
  }

  redirectToServerPage (response) {
    location.href = response.showPath
  }

  followRedirect (response) {
    location.href = response.url
  }
}

export default EventSimpleNewModel
