import React from 'react'
import EventsList from './EventsList'
import EventsIndexModel from './index/model/EventsIndexModel'
import { edit_event_path, new_event_path, event_path } from '../../routes.js.erb'
import TranslationProvider from '../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'
// import EventsPagesOptionsList from './events/EventsPagesOptionsList';

export default class EventsApp extends React.Component {
  constructor (props) {
    super(props)
    this.model = new EventsIndexModel({
      events: this.props.events,
      newEventPath: new_event_path,
      editEventPathTemplate: edit_event_path('---id---'),
      viewEventPathTemplate: event_path('---id---'),
      deleteEventPathTemplate: event_path('---id---')
    })
    this.locale = props.locale
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
            <div>
                <div className="events__head d-flex justify-content-between align-items-start">
                    <div className="events__text">
                        <h4 className="mt-0"><FormattedMessage id='spa.events_list' defaultMessage='Welcome'/></h4>
                    </div>
                    <div className="events__create">
                        <div className='actions'>
                            <a href={this.props.newEventPath}>
                                <button className='submit d-flex align-items-center'>
                                    <FormattedMessage id='spa.create_event' defaultMessage='Create new event'/>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="events__body">
                    <EventsList
                        editEventPathTemplate={this.props.editEventPathTemplate}
                        viewEventPathTemplate={this.props.viewEventPathTemplate}
                        model={this.model}
                        microAppTypes={this.props.microAppTypes}
                    />
                </div>
            </div>
        </TranslationProvider>
    )
  }
}
