import React, { Component } from 'react'
import VendorInterestsTimer from './VendorInterestsTimer'
import VendorInterestsForm from './VendorInterestsForm'
import GirlBudgeting from '../../../../assets/images/vendors/icons/small/girl_planning_budget.png'
import HappyMan from '../../../../assets/images/vendors/icons/small/happy_thankful_man_h.png'
import LongChecklist from '../../../../assets/images/vendors/icons/small/long_deformed_screen.png'
import ManagingTools from '../../../../assets/images/vendors/icons/small/web_elements_background.png'
import OpenedGift from '../../../../assets/images/vendors/icons/small/open_present.png'
import PlanningBoard from '../../../../assets/images/vendors/icons/planning_board.png'
import ThankyouLetter from '../../../../assets/images/vendors/icons/small/thank_you_letter_in.png'
import UpArrow from '../../../../assets/images/vendors/icons/up_arrow.png'
import VolumeUpArrow from '../../../../assets/images/vendors/icons/small/up_arrow_volumetric.png'
import SentIcon from '../../../../assets/images/simple_form/icons/sent_icon.svg'
import CelebrationStock from '../../../../assets/images/vendors/stock/celebration_stock.jpg'
import DinnerStock from '../../../../assets/images/vendors/stock/dinner_stock.jpg'
import VendorInterestsModel from './model/VendorInterestsModel'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

export default class VendorInterestsApp extends Component {
  model: VendorInterestsModel
  locale: string

  constructor (props: any) {
    super(props)
    this.model = new VendorInterestsModel({
      errors: props.errors || [],
      vendorInterestFields: props.vendor_interest || {}
    })
    this.locale = props.locale
  }

  componentDidMount () {
    this.model.setRemainingTime()
    setInterval(() => {
      this.model.setRemainingTime()
    }, 1000)
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <div className='vendor_interests main'>
                    <div className="block heading d-flex justify-content-center" id="main-interest-form">
                        <div className="block_content w-100 d-flex gap-5">
                            <div className="block_info d-flex flex-column align-items-center align-items-md-start">
                                <h1>
                                    <FormattedMessage
                                        id='vendor_interests.main_heading'
                                        defaultMessage='Get new clients for FREE'/>

                                </h1>
                                <div className="content_line"></div>
                                <h4>
                                    <FormattedMessage
                                        id='vendor_interests.main_paragraph'
                                        defaultMessage='We are looking for best vendors for our events'
                                    />
                                </h4>
                                <div className="block_form mt-4 d-block d-md-none">
                                    <VendorInterestsForm model={this.model} locale={this.locale}/>
                                </div>
                                <VendorInterestsTimer model={this.model}/>
                            </div>
                            <div className="block_form d-none d-md-block">
                                <VendorInterestsForm model={this.model} locale={this.locale}/>
                            </div>
                        </div>
                    </div>
                    <div className="block about_us d-flex justify-content-center">
                        <div className="block_content w-100">
                            <div className="block_title mb-5">
                                <h2 className='mb-3'>
                                    <FormattedMessage
                                        id='vendor_interests.what_is.title'
                                        defaultMessage='We are looking for best vendors for our events'
                                    />

                                </h2>
                                <p>
                                    <FormattedMessage
                                        id='vendor_interests.what_is.desc'
                                        defaultMessage='Planning.how helps clients organize any event with ease and convenience
                            by providing simple and effective tools'
                                    />

                                </p>
                            </div>
                            <div className="block_instruments mb-5 d-flex flex-wrap align-items-center">
                                <div className="block_instrument">
                                    <div className="block_img mb-3 d-flex align-items-end">
                                        <img width={'228px'} height={'166px'} src={GirlBudgeting} alt=""/>
                                    </div>
                                    <h6 className='mb-3'>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.budget_manage_title'
                                            defaultMessage='Budget management'
                                        />

                                    </h6>
                                    <p>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.budget_manage_desc'
                                            defaultMessage='Tools for event planning and budget management to ensure
                                event runs smoothly!'
                                        />
                                    </p>
                                </div>
                                <div className="block_instrument">
                                    <div className="block_img mb-3 d-flex align-items-end">
                                        <img width={'228px'} height={'190px'} src={ThankyouLetter} alt=""/>
                                    </div>
                                    <h6 className='mb-3'>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.invites_title'
                                            defaultMessage='Invitation'
                                        />

                                    </h6>
                                    <p>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.invites_desc'
                                            defaultMessage='Send invitations to your event via SMS and Email in just 2 clicks
                                and to any number of guests'
                                        />
                                    </p>
                                </div>
                                <div className="block_instrument long_checklist">
                                    <div className="block_img mb-3 d-flex align-items-end">
                                        <img width={'141px'} height={'175px'} src={LongChecklist} alt=""/>
                                    </div>
                                    <h6 className='mb-3'>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.checklist_title'
                                            defaultMessage='Checklists'
                                        />
                                    </h6>
                                    <p>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.checklist_desc'
                                            defaultMessage='With our checklists for any event you will definitely not forget
                                anything! You can also create your own'
                                        />
                                    </p>
                                </div>
                                <div className="block_instrument">
                                    <div className="block_img mb-3 d-flex align-items-end">
                                        <img width={'178px'} height={'181px'} src={OpenedGift} alt=""/>
                                    </div>
                                    <h6 className='mb-3'>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.giftlist_title'
                                            defaultMessage='Gift List'
                                        />

                                    </h6>
                                    <p>
                                        <FormattedMessage
                                            id='vendor_interests.what_is.giftlist_desc'
                                            defaultMessage='The issue with gifts has been resolved! Create your wish list
                                of gifts and share it with your guests'
                                        />

                                    </p>
                                </div>
                            </div>
                            <a href="#main-interest-form">
                                <button className='black_button'>
                                    <FormattedMessage
                                        id='vendor_interests.main_button_placeholder'
                                        defaultMessage='Submit your application'
                                    />

                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="block cooperation_benefits d-flex justify-content-center">
                        <div className="block_content w-100 d-flex gap-5">
                            <div className="block_title">
                                <h2>
                                    <FormattedMessage
                                        id='vendor_interests.cooperation.title'
                                        defaultMessage='Why should we cooperate?'
                                    />

                                </h2>
                                <div className="block_benefits mt-5 d-flex flex-column gap-3">
                                    <div className="block_benefit d-flex gap-3">
                                        <img width={'104px'} src={HappyMan} alt=""/>
                                        <div className="block_benefit-info d-flex flex-column justify-content-center">
                                            <h6 className='mb-2'>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.free_title'
                                                    defaultMessage='FOR FREE'
                                                />

                                            </h6>
                                            <p>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.free_desc'
                                                    defaultMessage='Placing your services on our platform is absolutely free for each
                                        vendor'
                                                />

                                            </p>
                                        </div>
                                    </div>
                                    <div className="block_benefit d-flex gap-3">
                                        <div className="block_img">
                                            <img width={'79px'} src={VolumeUpArrow} alt=""/>
                                        </div>
                                        <div className="block_benefit-info d-flex flex-column justify-content-center">
                                            <h6 className='mb-2'>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.traffic_title'
                                                    defaultMessage='Traffic'
                                                />

                                            </h6>
                                            <p>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.traffic_desc'
                                                    defaultMessage='Get additional traffic from Ideal Audiences with Planning.how'
                                                />

                                            </p>
                                        </div>
                                    </div>
                                    <div className="block_benefit d-flex gap-3">
                                        <img width={'104px'} src={ManagingTools} alt=""/>
                                        <div className="block_benefit-info  d-flex flex-column justify-content-center">
                                            <h6 className='mb-2'>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.manage_title'
                                                    defaultMessage='Control'
                                                />

                                            </h6>
                                            <p>
                                                <FormattedMessage
                                                    id='vendor_interests.cooperation.manage_desc'
                                                    defaultMessage='Manage your orders and customers with our user-friendly interface'
                                                />

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block_img_stock">
                                <img width={'432px'} height={'516px'} src={CelebrationStock} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="block partnership d-flex justify-content-center">
                        <div className="block_content w-100">
                            <div className="block_title">
                                <h2>
                                    <FormattedMessage
                                        id='vendor_interests.partnership.title'
                                        defaultMessage='How does the partnership work?'
                                    />

                                </h2>
                            </div>
                            <div
                                className="block_steps d-flex gap-5 flex-wrap align-items-center justify-content-center">
                                <div className="block_step d-flex gap-3">
                                    <div className="block_number">1</div>
                                    <div className='block_info'>
                                        <h6 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.connection_title'
                                                defaultMessage='Connection'
                                            />

                                        </h6>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.connection_desc'
                                                defaultMessage='Leave a request on this website and our manager will contact
                                    you as soon as possible'
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="block_step d-flex gap-3">
                                    <div className="block_number">2</div>
                                    <div className='block_info'>
                                        <h6 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.placement_title'
                                                defaultMessage='Placement'
                                            />

                                        </h6>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.placement_desc'
                                                defaultMessage='You will fill out a form in which you indicate services and
                                    company information to create an account'
                                            />

                                        </p>
                                    </div>
                                </div>
                                <div className="block_step d-flex gap-3">
                                    <div className="block_number">3</div>
                                    <div className='block_info'>
                                        <h6 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.public_traffic_title'
                                                defaultMessage='Publishing & Traffic'
                                            />

                                        </h6>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partnership.public_traffic_desc'
                                                defaultMessage='Ready! You will get access to your account where you can
                                    track requests for services from our clients'
                                            />

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a href="#main-interest-form">
                                <button className='black_button'>
                                    <FormattedMessage
                                        id='vendor_interests.main_button_placeholder'
                                        defaultMessage='Submit your application'
                                    />
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="block our_numbers d-flex justify-content-center">
                        <div className="block_content w-100">
                            <div className="block_title">
                                <h2 className='mb-4'>
                                    <FormattedMessage
                                        id='vendor_interests.our_numbers.title'
                                        defaultMessage='Our numbers'
                                    />
                                </h2>
                                <p>
                                    <FormattedMessage
                                        id='vendor_interests.our_numbers.desc'
                                        defaultMessage='We have been on the market for more than 3 years and with our tools we have helped our clients create unforgettable events'
                                    />

                                </p>
                            </div>
                            <div
                                className="block_statistics d-flex gap-4 flex-wrap align-items-center justify-content-center">
                                <div className="block_statistic d-flex align-items-center">
                                    <div className="block_info">
                                        <h3>15 000+</h3>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.our_numbers.invites'
                                                defaultMessage='Invitations'
                                            />

                                        </p>
                                    </div>
                                    <img width={'50px'} height={'50px'} src={SentIcon} alt=""/>
                                </div>
                                <div className="block_statistic d-flex align-items-center">
                                    <div className="block_info">
                                        <h3>220+</h3>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.our_numbers.events'
                                                defaultMessage='Events'
                                            />

                                        </p>
                                    </div>
                                    <img width={'50px'} height={'50px'} src={PlanningBoard} alt=""/>
                                </div>
                                <div className="block_statistic d-flex align-items-center">
                                    <div className="block_info">
                                        <h3>50 000</h3>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.our_numbers.monthly_traffic'
                                                defaultMessage='Monthly website traffic'
                                            />
                                        </p>
                                    </div>
                                    <img width={'50px'} height={'50px'} src={UpArrow} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block partners_list d-flex justify-content-center">
                        <div className="block_content w-100">
                            <div className="block_title">
                                <h2>
                                    <FormattedMessage
                                        id='vendor_interests.partners.title'
                                        defaultMessage='Who do we collaborate with?'
                                    />
                                </h2>
                            </div>
                            <div className="block_partners d-flex gap-4 flex-wrap align-items-start">
                                <div className="block_partner">
                                    <div className="block_info">
                                        <h5 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partners.typography_title'
                                                defaultMessage='Printing houses'
                                            />

                                        </h5>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partners.typography_desc'
                                                defaultMessage='In addition to e-invitations, we also offer the option to send
                                    physical invitations'
                                            />

                                        </p>
                                    </div>
                                </div>
                                <div className="block_partner">
                                    <div className="block_info">
                                        <h5 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partners.event_agency_title'
                                                defaultMessage='Event agencies'
                                            />

                                        </h5>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partners.event_agency_desc'
                                                defaultMessage='We are glad to cooperate with event agencies that are ready
                                    to help our clients organize events'
                                            />

                                        </p>
                                    </div>
                                </div>
                                <div className="block_partner">
                                    <div className="block_info">
                                        <h5 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partners.decorators_title'
                                                defaultMessage='Decorators'
                                            />
                                        </h5>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partners.decorators_desc'
                                                defaultMessage='Any major event involves decorations, and we are happy to collaborate
                                    with agencies that will help our clients'
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="block_partner">
                                    <div className="block_info">
                                        <h5 className='mb-2'>
                                            <FormattedMessage
                                                id='vendor_interests.partners.catering_title'
                                                defaultMessage='Catering'
                                            />
                                        </h5>
                                        <p>
                                            <FormattedMessage
                                                id='vendor_interests.partners.catering_desc'
                                                defaultMessage='We will be happy to cooperate with catering companies that can
                                    provide events for our clients'
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="block_additional_info w-100 d-flex justify-content-center">
                                <div className='block_additional_info-content'>
                                    <h3>
                                        <FormattedMessage
                                            id='vendor_interests.partners.additional_info'
                                            defaultMessage='We are happy to cooperate with any vendor related to events: photographers,
                                Djs, animators and others'
                                        />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block last_step d-flex justify-content-center">
                        <div className="block_content w-100 d-flex">
                            <div className="block_info">
                                <div className="block_title mb-3">
                                    <h1>
                                        <FormattedMessage
                                            id='vendor_interests.one_step_title'
                                            defaultMessage='Just 1 step to partnership!'
                                        />
                                    </h1>
                                </div>
                                <div className="block_form">
                                    <VendorInterestsForm model={this.model} locale={this.locale}/>
                                </div>
                                <div className="block_timer">
                                    <VendorInterestsTimer model={this.model}/>
                                </div>
                            </div>
                            <div className="block_img">
                                <img width={'465px'} height={'532px'} src={DinnerStock} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </TranslationProvider>
    )
  }
}
