import { observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const GuestsActionsToolbar = observer(({ model }) => {
  return (<div className="guests__head mt-3 d-flex justify-content-start align-items-start">
        <div className="guests__create mt-4 mt-sm-0 d-flex align-items-center gap-3 actions">
            <button
                disabled={model.isGuestsListEmpty || model.isServerSubmitDisabled}
                className={'invites_btn submit'}
                onClick={() => {
                  model.setModalEnsureOpened(true)
                }}
            >
                <FormattedMessage id='guests.send_invites' defaultMessage='Send Invites'/>
            </button>
        </div>
    </div>)
})

export default GuestsActionsToolbar
