import { observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const EventAcceptInviteForm = observer(({ model, intl }) => {
  const yesButtonClasses = model.attending === 'yes' ? 'submit' : 'cancel_btn'
  const maybeButtonClasses = model.attending === 'maybe' ? 'submit' : 'cancel_btn'
  const noButtonClasses = model.attending === 'no' ? 'submit' : 'cancel_btn'

  return (
        <React.Fragment>
            <form action="" method="POST">
                <div className='invite__responce'>
                    <h4 className='invite__responce-heading mb-3'>
                        <FormattedMessage id='spa.invitation_question' defaultMessage='Will you attend the event?'/>
                    </h4>
                    <div className='invite__controll d-flex actions'>
                        <input type='button' value={intl.formatMessage({ id: 'spa.yes_answer', defaultMessage: 'Yes' })}
                               onClick={() => model.signalAttending('yes')}
                               className={'invite__controll-button ' + yesButtonClasses} />
                        <input type='button' value={intl.formatMessage({ id: 'spa.maybe_answer', defaultMessage: 'Maybe' })}
                               onClick={() => model.signalAttending('maybe')}
                               className={'invite__controll-button ' + maybeButtonClasses} />
                        <input type='button' value={intl.formatMessage({ id: 'spa.no_answer', defaultMessage: 'No' })}
                               onClick={() => model.signalAttending('no')}
                               className={'invite__controll-button ' + noButtonClasses} />
                    </div>
                </div>
                <div className="comment actions">
                    <div className='d-flex justify-content-between my-2'>
                        <div className="note d-flex align-items-center gap-1">
                            <div className='tip__important'></div>
                            <p className='mb-0 note_message'>
                                <FormattedMessage id='spa.invitation_hint' defaultMessage='You can leave a comment for the person who sent you the invitation'/>
                            </p>
                        </div>
                    </div>
                    <textarea
                        onChange={e => model.setComment(e.target.value)}
                        value={model.comment}
                        placeholder={intl.formatMessage({ id: 'spa.comments_placeholder', defaultMessage: 'My comments' })}
                        name="comment"
                        cols="30"
                        rows="10"
                    />
                    <input type='hidden' name='attending' value={model.attending} />
                    <input
                        type='submit'
                        className='submit mt-3'
                        value={intl.formatMessage({ id: 'spa.save_answer', defaultMessage: 'Save answer' })} />
                </div>
            </form>
        </React.Fragment>
  )
})

export default injectIntl(EventAcceptInviteForm)
