import { observer } from 'mobx-react'
import React from 'react'
import GuestsAnswerPopUp from './GuestsAnswerPopUp'
import { FormattedMessage, injectIntl } from 'react-intl'
import PhoneWithCode from '../editor/ReactQuill/PhoneWithCode'

const GuestsItem = observer(({ currentGuestId, model, intl }) => {
  const currentGuest = model.allGuests[currentGuestId]
  const isReadyToSave = model.guestsUserInputs[currentGuestId].isReadyToSave
  const statusMap = {
    none: {
      cssClass: 'no-inv',
      locale_id: 'guests.not_invited_status',
      label: 'Not invited'
    },
    new: {
      cssClass: 'no-inv',
      locale_id: 'guests.not_invited_status',
      label: 'Not invited'
    },
    in_progress: {
      cssClass: 'inv',
      locale_id: 'guests.in_progress_status',
      label: 'In progress'
    },
    send: {
      cssClass: 'ship',
      locale_id: 'guests.shipped_status',
      label: 'Shipped'
    },
    cancelled: {
      cssClass: 'no-inv',
      locale_id: 'guests.not_invited_status',
      label: 'Not invited'
    }
  }

  const inviteStatusDataEmail = statusMap[currentGuest.inviteStatus.email]
  const inviteStatusDataText = statusMap[currentGuest.inviteStatus.sms]

  let answerStatus = 'noAnswer'
  if (currentGuest.answer && ('attending' in currentGuest.answer)) {
    if (currentGuest.answer.attending == 'yes') {
      answerStatus = 'positiveAnswer'
    } else if (currentGuest.answer.attending == 'maybe') {
      answerStatus = 'neutralAnswer'
    } else {
      answerStatus = 'negativeAnswer'
    }
  }

  return (
        <div id={currentGuestId}
             className={`guests__item row-md ${isReadyToSave ? 'guests__item__edit' : ''}`}
        >
            <div className="guests__item_fields row md-p-0 gap-2">
                <div className="guests__item_col col-2 name d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.name' defaultMessage='Name'/>
                    </div>
                    <input
                        type="text"
                        value={model.guestsUserInputs[currentGuestId].name}
                        onChange={(e) => model.setGuestName(currentGuestId, e.target.value)}
                        className="input__edit m-0"
                    />
                </div>

                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col contact d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.email' defaultMessage='Email'/>
                        <span className='optional_flag ms-1'>
                            <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                        </span>
                    </div>
                    <div
                        className={`guests__item_mail d-flex align-items-center validation-${model.guestsUserInputs[currentGuestId].emailErrorActive}`}>
                        <input
                            type="email"
                            value={model.guestsUserInputs[currentGuestId].email}
                            onChange={(e) => model.setGuestEmail(currentGuestId, e.target.value)}
                            className="input__edit m-0"
                        />
                    </div>
                </div>
                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col contact d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.phone' defaultMessage='Phone number'/>
                        <span className='optional_flag ms-1'>
                            <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                        </span>
                    </div>
                    <div className="guests__item_phone d-flex align-items-center gap-2">
                        <PhoneWithCode
                          countryCode={model.guestsUserInputs[currentGuestId].countryCode}
                          countryCodeCallback={model.setGuestCountryCode.bind(model, currentGuestId)}
                          phone={model.guestsUserInputs[currentGuestId].phone}
                          phoneCallback={model.setGuestPhone.bind(model, currentGuestId)}
                          phoneClassname={`input__edit m-0 validation-${model.guestsUserInputs[currentGuestId].phoneErrorActive}`}
                          readOnly={false}
                        />
                    </div>
                </div>
                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col-1 status d-flex flex-row align-items-center">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.status' defaultMessage='Status'/>
                    </div>
                    <div className='info__text-status'>
                        <div>
                            <p>Email: </p>
                            <button className="status__button" id={inviteStatusDataEmail.cssClass}>
                                <FormattedMessage id={inviteStatusDataEmail.locale_id}
                                                  defaultMessage={inviteStatusDataEmail.label}/>
                            </button>
                        </div>
                        <div>
                            <p>Text: </p>
                            <button className="status__button" id={inviteStatusDataText.cssClass}>
                                <FormattedMessage id={inviteStatusDataText.locale_id}
                                                  defaultMessage={inviteStatusDataText.label}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col-1 answer d-flex flex-row align-items-center">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.answer' defaultMessage='Answer'/>
                    </div>
                    <div>
                      <button disabled={!('attending' in currentGuest.answer)}
                              onClick={() => model.openPopupWithGuest(currentGuestId)}
                              className={`answer__button d-flex align-items-center ${answerStatus}`}>
                        {answerStatus === 'positiveAnswer' &&
                          <span className={`ms-2 ${answerStatus}`}>
                            {intl.formatMessage({
                              id: 'spa.yes_answer'
                            })}
                          </span>
                        }
                        {answerStatus === 'neutralAnswer' &&
                          <span className={`ms-2 ${answerStatus}`}>
                            {intl.formatMessage({
                              id: 'spa.maybe_answer'
                            })}
                          </span>
                        }
                        {answerStatus === 'negativeAnswer' &&
                          <span className={`ms-2 ${answerStatus}`}>
                            {intl.formatMessage({
                              id: 'spa.no_answer'
                            })}
                          </span>
                        }
                      </button>
                    </div>
                    {model.isPopupOpen(currentGuestId) &&
                        <GuestsAnswerPopUp model={model} currentGuest={currentGuest}/>
                    }
                </div>

                <div className="guests__item_col col-2 controlls d-flex justify-content-start align-items-center">
                    {isReadyToSave
                        && (
                            <div className='actions'>
                                <button
                                    className='button_accept submit'
                                    onClick={() => model.saveGuestOnServer(currentGuestId)}>
                                    <FormattedMessage id='spa.save' defaultMessage='Save'/>
                                </button>
                                <button
                                    className='button_accept cancel_btn'
                                    onClick={() => model.clearInput(currentGuestId)}>
                                    <FormattedMessage id='spa.cancel' defaultMessage='Cancel'/>
                                </button>
                            </div>
                        )
                    }
                    <div
                        className="delete"
                        onClick={() => model.serverDeleteGuest(currentGuestId)}
                    ></div>
                </div>
            </div>
        </div>
  )
})

export default injectIntl(GuestsItem)
