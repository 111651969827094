import { observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const GuestsInfo = observer(({ isMock }) => {
  return (
        <div className="guests__item_info row">
            <div className="guests__item_col col-2 name">
                <p><FormattedMessage id='guests.name' defaultMessage='Name'/></p>
            </div>
            <div className="guests__item_col col contact">
                <p>
                    <FormattedMessage id='guests.email' defaultMessage='Email'/>
                    <span className='optional_flag ms-1'>
                        <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                    </span>
                </p>
            </div>
            <div className="guests__item_col col contact">
                <p>
                    <FormattedMessage id='guests.phone' defaultMessage='Phone number'/>
                    <span className='optional_flag ms-1'>
                        <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                    </span>
                </p>
            </div>
            <div className="guests__item_col col-1 status">
                {!isMock &&
                    <p><FormattedMessage id='guests.status' defaultMessage='Status'/></p>
                }
            </div>
            <div className="guests__item_col col-1 answer">
                {!isMock &&
                    <p><FormattedMessage id='guests.answer' defaultMessage='Answer'/></p>
                }
            </div>
            <div className="guests__item_col col-2">
            </div>
        </div>
  )
})
export default GuestsInfo
