import React from 'react'
import InitialToDoList from './InitialToDoList'
import InitialToDoHeader from './InitialToDoHeader'
import TranslationProvider from '../providers/TranslationProvider'

class InitialToDoApp extends React.Component {
  constructor (props) {
    super(props)
    this.locale = props.locale
    this.initial_todos = props.initial_todos
  }

  render () {
    return (
      <div className="todo-app-container">
        <TranslationProvider user_locale={this.locale}>
          <React.Fragment>
            <InitialToDoHeader />
            <InitialToDoList initialTodos={this.initial_todos} />
          </React.Fragment>
        </TranslationProvider>
      </div>
    )
  }
}

export default InitialToDoApp
