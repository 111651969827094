import React, { FC } from 'react'

const CircularLoading: FC = () => {
  return (
    <div className='loading_main'>
      <div className="circular_loader">
          <div className="loader"></div>
      </div>
    </div>
  )
}

export default CircularLoading
