import React from 'react'
import { observer } from 'mobx-react'
import ToDoAddButton from './ToDoAddButton'
import { FormattedMessage } from 'react-intl'
import { initial_todos_path } from '../../routes.js.erb'

const ToDoHeader = observer(({ model, scrollViewRef }) => {
  return (
        <div className='head_all d-flex'>
            <div className="mt-2">
                <div className="col-xxl-8 description">
                    <div className="row">
                        <div className="col-10">
                            <h5 className="lead">
                                {(model.haveInitialTemplate && model.sortedItems.length == 0) &&
                                    <React.Fragment>
                                        <p>
                                            <FormattedMessage
                                                id='spa.checklist_initial_todos_description'
                                                defaultMessage='Your checklist seems a bit empty, check out {link} to get started.'
                                                values={
                                                    {
                                                      link: <a href={initial_todos_path({ locale: LOCALE || 'en' })}>
                                                            <FormattedMessage
                                                                id='spa.our_template'
                                                                defaultMessage='our template'
                                                            />
                                                        </a>
                                                    }
                                                }/>
                                        </p>
                                        <div className='actions initial-todo'>
                                            <button
                                                onClick={() => model.copyInitialTodos()}
                                                className="btn todo-add-btn submit mt-2"
                                                disabled={model.isLoading}
                                            >
                                                <FormattedMessage
                                                    id='spa.checklist_initial_todos_template'
                                                    defaultMessage='Add tasks from template'/>
                                            </button>
                                        </div>
                                    </React.Fragment>}
                                {(model.sortedItems.length > 0 || !model.haveInitialTemplate) &&
                                    <FormattedMessage id='spa.checklist_description'
                                                      defaultMessage='Easy and simple checklist, that would help you plan your event from start to finish.'/>
                                }
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="head__controll">
                <ToDoAddButton scrollViewRef={scrollViewRef} model={model}/>
            </div>
        </div>
  )
})
export default ToDoHeader
