import React, { Component } from 'react'
import ToDoEditor from './ToDoEditor'

class ToDoItem extends Component {
  constructor (props) {
    super(props)
    this.colEditorRef = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.colEditorRef.current && !this.colEditorRef.current.contains(event.target)) {
      this.cancelEditing()
    }
  }

  cancelEditing = () => {
    this.props.model.setEditing(this.props.currentTaskId, false)
  }

  startEditingArea = (event) => {
    event.stopPropagation()
    const { model, currentTaskId } = this.props
    if (!model.getEditing(currentTaskId)) {
      // Check if link is not pressed
      if (event.target.tagName !== 'A') {
        model.setEditing(currentTaskId, true)
      }
    }
  }

  render () {
    const { currentTaskId, model } = this.props
    const itemStyleClasses = 'item my-3 mx-0 px-3' + (model.getJustCreated(currentTaskId) ? ' just-created' : '')

    return (
        <React.Fragment>
          <div className={itemStyleClasses}>
            <div ref={this.colEditorRef} className="row d-flex border-0">
              <label htmlFor="todo_checkbox" className="col-2 py-4 d-flex align-items-center">
                <input
                    onChange={() => model.toggleDone(currentTaskId)}
                    type="checkbox"
                    name="todo_checkbox"
                    checked={model.getDone(currentTaskId)}
                    className="form-check-input"
                />
              </label>
              {model.getEditing(currentTaskId) ?
                  (
                    <div className="col py-4 d-flex align-items-center col-editor">
                      <ToDoEditor currentTaskId={currentTaskId} model={model}/>
                    </div>
                  ) :
                  (
                    <div onClick={this.startEditingArea} className="col py-4 d-flex align-items-center col-editor">
                      <ToDoEditor currentTaskId={currentTaskId} model={model}/>
                    </div>
                  )
              }
              <div className="col-1 py-1 d-flex gap-2">
                <div
                    className="todo-edt-btn px-2"
                    onClick={() => model.setEditing(currentTaskId, true)}
                >
                  {/* <AiOutlineEdit/> */}
                </div>
                <div
                    className="todo-rmv-btn px-2"
                    onClick={() => model.removeTodo(currentTaskId)}
                >
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

export default ToDoItem
