import React, { FC } from 'react'
import IGuest from './interfaces/IGuest'
import { FormattedMessage } from 'react-intl'

interface IGuestsAnswerPopUp {
  currentGuest: IGuest
  model: any // bad code, fix when find typescript solution for mobx
}

const GuestsAnswerPopUp:FC<IGuestsAnswerPopUp> = ({ currentGuest, model }) => {
  return (
    <div className='answer__popup d-flex justify-content-center align-items-center w-100 h-100'>
      <div className='answer__popup-info p-4'>
          <div className="answer__popup-head d-flex justify-content-between align-items-center">
              <h3 className='answer__popup-title'>{currentGuest.name}</h3>
              <button className='close__cross' onClick={() => model.closePopup()}></button>
          </div>
          <div className="answer__popup-line my-3"></div>
          <div className="answer__popup-body">
            <div className="answer__popup-block">
              <h5 className="answer__popup-title mb-1">
                <FormattedMessage id='guests.answer' defaultMessage='Answer' />
              </h5>

              {(currentGuest.answer.attending == 'yes')
                ?
                <p className='answer__popup-paragraph mr-1 positive d-flex align-items-center'>
                  <FormattedMessage id='spa.yes_answer' defaultMessage='Yes' />
                </p>
                : (currentGuest.answer.attending == 'maybe')
                    ?
                    <p className='answer__popup-paragraph mr-1 neutral d-flex align-items-center'>
                      <FormattedMessage id='spa.maybe_answer' defaultMessage='Maybe' />
                    </p>
                    :
                    <p className='answer__popup-paragraph mr-1 negative d-flex align-items-center'>
                      <FormattedMessage id='spa.no_answer' defaultMessage='No' />
                    </p>
              }
            </div>
            <div className="answer__popup-block">
              <h5 className="answer__popup-title mb-1">
                <FormattedMessage id='guests.comment' defaultMessage='Comment' />
              </h5>
              <p className='answer__popup-paragraph'>
                {currentGuest.answer.comment}
              </p>
            </div>
          </div>
          <div className="answer__popup-controll mt-4">
            <div className="actions">
              <button className='submit' onClick={() => model.closePopup()}>
                <FormattedMessage id='guests.okay' defaultMessage='Okay' />
              </button>
            </div>
          </div>
      </div>
    </div>
  )
}

export default GuestsAnswerPopUp
