import React from 'react'
import GuestItemEditControlls from './GuestItemEditControlls'
import GuestItemEditInput from './GuestItemEditInput'
import GuestsItemEditModel from './model/GuestItemEditModel'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

export default class GuestsItemEdit extends React.Component {
  constructor (props) {
    super(props)
    this.model = new GuestsItemEditModel(
      {
        eventId: props.eventId,
        currentGuestInfo: props.guest
      }
    )
    this.type = props.type
    this.locale = props.locale
  }

  componentDidMount (): void {
    if (ahoy) {
      ahoy.track('GuestsItemEdit mounted')
    }
    if (fbq) {
      fbq('track', 'ViewContent', {
        content_name: 'GuestsItemEdit mounted'
      })
    }
    if (gtag) {
      gtag('event', 'ViewContent', {
        content_name: 'GuestsItemEdit mounted'
      })
    }
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
            <div className="content guests__edit w-100 d-flex">
                <form className="d-flex w-100 justify-content-center image__guests">
                    <section className="section guests my-5 d-flex flex-column align-items-center">
                        <GuestItemEditInput model={this.model} type={this.type} />
                        <GuestItemEditControlls model={this.model} />
                        <div className="w-100 mt-3"></div>
                        <div className='spam_alert'>
                          <p>
                            <FormattedMessage id='guests.agreement' defaultMessage='I agree to not send unsolicited and/or marketing messages'/>
                          </p>
                        </div>
                    </section>
                </form>
            </div>
        </TranslationProvider>
    )
  }
}
