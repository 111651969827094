import { observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const ToDoAddButton = observer(({ model, scrollViewRef, intl }) => {
  const scrollView = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  return (
        <div className="d-flex flex-row-reverse actions">
            <button
                type="button"
                className="btn todo-add-btn submit"
                onClick={
                    () => {
                      model.addTodo({
                        description: intl.formatMessage({
                          id: 'spa.default_message',
                          defaultMessage: 'Type any task description you want'
                        }),
                        done: false
                      })
                      scrollView(scrollViewRef)
                    }
                }>
                <div className='button_icon'>
                </div>
                <FormattedMessage id='spa.add_task' defaultMessage='Add task' />
            </button>
        </div>
  )
})

export default injectIntl(ToDoAddButton)
