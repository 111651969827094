interface IFullLanguges {
  [key: string]: string
}

export const fullLanguges: IFullLanguges = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  ru: 'Русский',
  ko: '한국어',
  ja: '日本語',
  kk: 'Қазақша',
  ky: 'Кыргызча',
  uk: 'Українська'
}
