import React, { Component } from 'react'
import SuppliersList from '../suppliers/SuppliersList'
import VendorsSearchModel from './model/VendorsSearchModel'
// import SortButton from './SortButton'
import { LocationCategory, Vendor } from './types/Vendor.type'
import TranslationProvider from '../../providers/TranslationProvider'
import VendorsServiceList from './VendorsServiceList'
import VendorSearchForm from './form/VendorSearchForm'

interface VendorsSearchAppProps {
    vendors: Vendor[]
    locale: string
    locations: LocationCategory[]
    categories: LocationCategory[]
    location: LocationCategory
    category: LocationCategory
    totalPages: number
    popularVendors: Vendor[]
    currentPage: number
}

export default class VendorsSearchApp extends Component<VendorsSearchAppProps> {
  model: any

  constructor (props: VendorsSearchAppProps) {
    super(props)
    this.model = new VendorsSearchModel({
      locale: props.locale,
      vendors: props.vendors,
      locations: props.locations,
      categories: props.categories,
      location: props.location,
      category: props.category,
      totalPages: props.totalPages,
      popularVendors: props.popularVendors,
      currentPage: props.currentPage
    })
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorsSearchApp mounted')
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.model.locale}>
                <div className='search_vendor'>
                    <SuppliersList model={this.model}/>
                    <div className="brand_content_line mt-5 mb-5"></div>
                    <div className="search_header mt-3 mt-md-0 d-flex justify-content-between">
                        <div className='d-flex mb-3 mb-md-0 flex-column flex-md-row gap-3 align-items-center flex-wrap'>
                            <VendorSearchForm model={this.model}/>
                        </div>
                        {/* <SortButton model={this.model}/> */}
                    </div>
                    <VendorsServiceList model={this.model}/>
                    {/* <div className="ad_banner mt-5 w-100">
                    <div className="ad_banner_content p-4 w-100">
                        <h1>
                            Лучшие флористы города Алматы!
                            <p>Скидка новым клиентами 20%</p>
                        </h1>
                    </div>
                    <div className="promo_info pt-1 pb-1">
                        Промо
                    </div>
                </div> */}
                </div>
            </TranslationProvider>
    )
  }
}
