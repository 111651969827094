import React, { Component } from 'react'
import EventInviteInfo from './EventInviteInfo'
import EventAcceptInviteModel from './model/EventAcceptInviteModel'
import EventAcceptInviteForm from './EventAcceptInviteForm'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

class EventAcceptInvite extends Component {
  constructor (props) {
    super(props)
    this.currentEvent = props.currentEvent
    this.userPhoto = props.userPhoto
    this.locale = props.locale
    this.giftRegistries = props.giftRegistries
    this.model = new EventAcceptInviteModel(
      {
        initialAnswer: props.initialAnswer
      })
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <React.Fragment>
                    <div className='events-accept__invite w-100'>
                        <div className='title'>
                            <h2 className='mb-2'><FormattedMessage id='spa.invitation_header'
                                                                   defaultMessage='You have been invited to an event!'/>
                            </h2>
                        </div>
                        <div className='event__info'>
                            <EventInviteInfo currentEventInfo={this.currentEvent} photoUrl={this.userPhoto}/>
                            {this.currentEvent && this.currentEvent.additional_info && this.currentEvent.additional_info.length > 0 && (
                                <div className='additional_info mt-4 p-3'>
                                    <h2 className='mb-2'><FormattedMessage id='spa.additionalInfo'
                                                                   defaultMessage='Additional information'/></h2>
                                    <p>{this.currentEvent.additional_info}</p>
                                </div>
                            )}
                            {this.giftRegistries.length > 0 && (
                                <div className='gift_registries-invitation mt-4 p-3'>
                                    <h2 className='mb-2'><FormattedMessage id='micro_app_types.gift_registry.name_plural'
                                                                   defaultMessage='Gift registries'/></h2>
                                    <ul>
                                        {this.giftRegistries.map(registry =>
                                            <li key={registry.external_url}>
                                                <a href={registry.external_url}>{registry.name}</a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <EventAcceptInviteForm model={this.model}/>
                    </div>
                </React.Fragment>
            </TranslationProvider>
    )
  }
}

export default EventAcceptInvite
