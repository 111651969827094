import React, { FC } from 'react'
import CakeLogo from '../../../../../assets/images/vendors/icons/cake_logo.svg'
import Star from '../../../../../assets/images/star.svg'

interface IPopularServicesItemProps {
    vendor: any
}

const PopularServicesItem: FC<IPopularServicesItemProps> = ({ vendor }) => {
  return (
    <div className='vendor'>
        <img className='vendor_img' alt='Cakes' src={vendor.image_url} />
        <div className='vendor_content d-flex align-items-center w-100'>
        <img className='vendor_logo' alt='CakeLogo' src={CakeLogo} />
        <div className='ml-2'>
            <h6>{vendor.name}</h6>
            <p>
            <img alt='Star' width='24px' height='24px' src={Star} /> 5 <span>(5 отзывов)</span>
            </p>
        </div>
        <span className='price highligted align-self-end ml-auto'>от 1500 ₸</span>
        </div>
    </div>
  )
}

export default PopularServicesItem
