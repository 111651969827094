import { makeObservable, observable, action, computed } from 'mobx'

interface ILoginModelProps {
    otp_login: boolean
    forceEmail: string
    forcePhone: string
    formType: string
    forceCountryCode?: string
}

type LoginStep = 'phone' | 'email' | 'password' | 'otp'

class LoginModel {
  isDisabled: boolean
  isInputEmpty: boolean
  otpLogin: boolean
  countryCode: string
  formType: string
  phoneInputValue: string
  emailInputValue: string
  otpInput: string
  otpRegex: RegExp
  tempNumberRegex: RegExp
  currentStep: LoginStep

  constructor ({ otp_login, forceEmail, forcePhone, formType, forceCountryCode }: ILoginModelProps) {
    this.isDisabled = false
    this.isInputEmpty = false
    this.otpInput = ''
    this.otpRegex = /^[0-9]{4}$/
    this.formType = formType
    this.otpLogin = otp_login
    this.tempNumberRegex = /^[0-9]*$/

    if (otp_login) {
      this.currentStep = 'phone'
    } else {
      this.currentStep = 'email'
    }
    if (formType === 'email_password') {
      this.currentStep = 'password'
    }
    if (formType === 'phone_otp') {
      this.currentStep = 'otp'
    }
    if (forceEmail) {
      this.emailInputValue = forceEmail
    } else {
      this.emailInputValue = ''
    }
    if (forcePhone) {
      this.phoneInputValue = forcePhone
    } else {
      this.phoneInputValue = ''
    }
    if (forceCountryCode) {
      this.countryCode = forceCountryCode
    } else {
      this.countryCode = '+1'
    }

    makeObservable(this, {
      isDisabled: observable,
      isInputEmpty: observable,
      countryCode: observable,
      phoneInputValue: observable,
      emailInputValue: observable,
      otpInput: observable,
      currentStep: observable,
      formType: observable,
      setCurrentStep: action,
      setIsDisabled: action,
      setIsInputEmpty: action,
      setCountryCode: action,
      setEmailInputValue: action,
      setPhoneInputValue: action,
      setOtpInput: action,
      shouldShowGoogleSignIn: computed,
      shouldShowFBSignIn: computed,
      shouldShowPasswordInput: computed,
      shouldShowAltDelimetr: computed,
      shouldShowNextButton: computed
    })
  }

  setCurrentStep (step: LoginStep) {
    this.currentStep = step
  }

  setIsDisabled (disabled: boolean) {
    this.isDisabled = disabled
  }

  setIsInputEmpty (isEmpty: boolean) {
    this.isInputEmpty = isEmpty
  }

  setCountryCode (countryCode: string) {
    this.countryCode = '+' + countryCode
  }

  setPhoneInputValue (phone: string) {
    if (this.tempNumberRegex.test(phone)) {
      this.phoneInputValue = phone
    }
  }

  setEmailInputValue (value: string) {
    this.emailInputValue = value
  }

  setOtpInput (otpInput: string) {
    if (otpInput.length < 5) this.otpInput = otpInput
  }

  get shouldShowGoogleSignIn () {
    return this.formType !== 'email_password' && (this.formType == 'google' || !this.formType)
  }

  get shouldShowFBSignIn () {
    return this.formType !== 'email_password' && (this.formType == 'facebook' || !this.formType)
  }

  get shouldShowPhoneSignIn () {
    return this.currentStep === 'email' && this.otpLogin
  }

  get shouldShowPasswordInput () {
    return this.formType === 'email_password' && this.currentStep === 'password'
  }

  get shouldShowOtpInput () {
    return this.formType === 'phone_otp' && this.currentStep === 'otp'
  }

  get shouldShowAltDelimetr () {
    return (this.currentStep === 'email' || this.currentStep === 'phone')
  }

  get shouldShowNextButton () {
    return (this.formType !== 'google' && this.formType !== 'facebook')
  }
}

export default LoginModel
