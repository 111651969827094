import React from 'react'
import ToDoItem from './ToDoItem'
import { observer } from 'mobx-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const ToDoList = observer(({ model }) => {
  const items = model.sortedItems
  return (
        <React.Fragment>
          <DragDropContext onDragEnd={result => model.reorderTodoItems(result)}>
            <Droppable droppableId='todo_items'>
              {(provided) => (
                <div className="todo-items-container" {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map(item => {
                    return (
                      <Draggable key={item.currentID} draggableId={item.currentID} index={item.sort}>
                        {(provided) => (
                          <div className='drag__wrapper' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ToDoItem model={model} currentTaskId={item.currentID} />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </React.Fragment>
  )
})

export default ToDoList
