import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface IVendorInterestsTimer {
    model: any
}

const VendorInterestsTimer: FC<IVendorInterestsTimer> = observer(({ model }) => {
  return (
        <div className='vendors_timer text-center'>
            <div className='timer_title'>
                <FormattedMessage
                    id='vendor_interests.timer.title'
                    defaultMessage='FREE advertising package available until 30.07'
                />
            </div>
            <div className="timer d-flex gap-4 justify-content-center">
                <div className="time">
                    <div className='time_number'>{model.remainingTime.days}</div>
                    <div className='time_name'>
                        <FormattedMessage
                            id='vendor_interests.timer.days'
                            defaultMessage='days'
                        />
                    </div>
                </div>
                <div className="time">
                    <div className='time_number'>{model.remainingTime.hours}</div>
                    <div className='time_name'>
                        <FormattedMessage
                            id='vendor_interests.timer.hours'
                            defaultMessage='hours'
                        />
                    </div>
                </div>
                <div className="time">
                    <div className='time_number'>{model.remainingTime.minutes}</div>
                    <div className='time_name'>
                        <FormattedMessage
                            id='vendor_interests.timer.minutes'
                            defaultMessage='minutes'
                        />
                    </div>
                </div>
                <div className="time">
                    <div className='time_number'>{model.remainingTime.seconds}</div>
                    <div className='time_name'>
                        <FormattedMessage
                            id='vendor_interests.timer.seconds'
                            defaultMessage='seconds'
                        />
                    </div>
                </div>
            </div>
        </div>
  )
})

export default VendorInterestsTimer
