import { makeObservable, observable, action, remove } from 'mobx'

class EventsIndexModel {
  events
  deleteEventPathTemplate

  constructor (initialData) {
    if (initialData.events) {
      this.events = initialData.events
    } else {
      this.events = []
    }

    this.deleteEventPathTemplate = initialData.deleteEventPathTemplate

    makeObservable(this, {
      events: observable,
      removeEvent: action
    })
  }

  removeEvent (eventId) {
    const csrfToken = document.querySelector("[name='csrf-token']").content

    for (let i = 0; i < this.events.length; i++) {
      const event = this.events[i]
      if (event.id === eventId) {
        fetch(this.deleteEventPathTemplate.replace('---id---', event.id), {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken
          }
        }).then(() => {
          remove(this.events, i)
        })
      }
    }
  }
}

export default EventsIndexModel
