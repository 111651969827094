import React, { Component, ReactNode } from 'react'

interface IArticleShow {
  title: ReactNode,
  content: HTMLElement
}

class ArticleShow extends Component<IArticleShow> {
  title: ReactNode
  content: HTMLElement
  constructor (props: IArticleShow) {
    super(props)
    this.title = props.title
    this.content = props.content
  }

  render () {
    return (
            <div className='article__content'>
              <div className='article__title'>
                <h1 className='article__title-heading mb-3'>{this.title}</h1>
              </div>
              <div className='article__body mt-4'>
                <div dangerouslySetInnerHTML={{ __html: this.content }} className='article__content'></div>
              </div>
            </div>
    )
  }
}

export default ArticleShow
