import React from 'react'
import GiftRegistriesIndexModel from './model/GiftRegistriesIndexModel'
import { observer } from 'mobx-react'
import GiftRegistryEdit from './GiftRegistryEdit'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage, injectIntl } from 'react-intl'

const GiftRegistriesIndexContent = injectIntl(observer(({ model, intl }) => {
  const registries = model.giftRegistries.map((current) => {
    return (<div className="gift_registry row mt-2 pb-2 border-bottom" key={`gr-${current.id}`}>
            <div className="col-4 name">
                {current.name}
            </div>
            <div className="col-4 url">
                {current.external_url}
            </div>
            <div className="col-4 d-flex flex-column gap-2">
                <button className="edit__button" disabled={model.isLoading} onClick={() => {
                  model.setRegistryEditing(true)
                  model.setTempRegistryName(current.name)
                  model.setTempRegistryURL(current.external_url)
                  model.setTempRegistryId(current.id)
                }}>
                </button>
                <button className="delete__button" disabled={model.isLoading} onClick={() => {
                  model.removeGiftRegistryFromServer(current.id)
                }}>
                </button>
            </div>
        </div>)
  })
  return (
        <React.Fragment>
            <div className='position-relative'>
              {model.isRegistryEditing &&
                <GiftRegistryEdit model={model} />}
            </div>
            <div><h2><FormattedMessage id="micro_app_types.gift_registry.name_plural" defaultMessage="Gift Registries" /></h2></div>
            <div className="mt-4">
                <div className="row mt-2 pb-2 border-bottom">
                    <div className="col-4">
                        <b><FormattedMessage id="micro_app_types.gift_registry.name_title" defaultMessage="Name" /></b>
                    </div>
                    <div className="col-4">
                        <b><FormattedMessage id="micro_app_types.gift_registry.url" defaultMessage="URL" /></b>
                    </div>
                    <div className="col-4">
                        <b><FormattedMessage id="micro_app_types.gift_registry.actions" defaultMessage="Actions" /></b>
                    </div>
                </div>
                {registries}
            </div>
            <div className="mt-4 pb-4">
                <h4><FormattedMessage id="micro_app_types.gift_registry.add_registry" defaultMessage="Add new registry" /></h4>
                <div className="mt-2 input__container">
                    <input type="text"
                           className="inputs"
                           value={model.newRegistry.name}
                           placeholder={
                            intl.formatMessage({
                              id: 'micro_app_types.gift_registry.name_title',
                              defaultMessage: 'Name'
                            })
                          }
                           onChange={(e) => {
                             model.setNewRegistryName(e.target.value)
                           }}
                    />
                </div>
                <div className="mt-2 input__container">
                    <input type="text"
                           className="inputs"
                           value={model.newRegistry.external_url}
                           placeholder={
                            intl.formatMessage({
                              id: 'micro_app_types.gift_registry.url',
                              defaultMessage: 'URL'
                            })
                           }
                           onClick={() => model.setIsUrlErrorActive(false)}
                           onChange={(e) => {
                             model.setNewRegistryExternalUrl(e.target.value)
                           }}
                    />
                </div>
                <div className="mt-2 actions position-relative">
                    {model.isUrlErrorActive &&
                      <div className='gift_registry-error p-3 position-absolute'>
                        <h3>URL is not correct, it must be absolute</h3>
                      </div>}
                    <button
                        className="submit"
                        disabled={model.isLoading}
                        onClick={() => {
                          if (model.absoluteUrlRegex.test(model.newRegistry.external_url)) { model.sendGiftRegistryToServer() } else model.setIsUrlErrorActive(true)
                        }}>
                      <FormattedMessage id="spa.save" defaultMessage="Save" />
                    </button>
                </div>
            </div>
        </React.Fragment>
  )
}))
export default class GiftRegistriesIndexApp extends React.Component {
  model: GiftRegistriesIndexModel
  locale: string

  constructor (props: any) {
    super(props)
    const initialRegistries = this.props.initial_registries ? this.props.initial_registries : []
    this.model = new GiftRegistriesIndexModel(initialRegistries, this.props.server_path)
    this.locale = props.locale
  }

  render () {
    const that = this
    return (
          <TranslationProvider user_locale={that.locale}>
            <div className="gift_registries__page">
                <GiftRegistriesIndexContent model={that.model}/>
            </div>
          </TranslationProvider>
    )
  }
}
