import React from 'react'
import { observer } from 'mobx-react'
import IMicroApp from './interfaces/IMicroApp'
import { FormattedMessage } from 'react-intl'
import { event_path } from '../../../routes.js.erb'

import ChecklistIcon from './../../../../assets/images/checklist.svg'
import InviteIcon from '../../../../assets/images/invitation.svg'
import GiftRegistryIcon from '../../../../assets/images/giftregistry.svg'
import BudgetIcon from '../../../../assets/images/budgeting.svg'
import ArrowIcon from '../../../../assets/images/arrow-closed.svg'

interface ImageMap {
    [key: string]: string
}

interface IMicroAppProps {
    event_id: string,
    microAppTypes: Array<IMicroApp>,
}

const images: ImageMap = {
  'checklist.svg': ChecklistIcon,
  'invitation.svg': InviteIcon,
  'giftregistry.svg': GiftRegistryIcon,
  'budgeting.svg': BudgetIcon
}

class MicroAppTypesApp extends React.Component<IMicroAppProps> {
  constructor (props: IMicroAppProps) {
    super(props)
  }

  render () {
    return (
            <div className='micro_app_types'>
                {this.props.microAppTypes.map((micro_app) => (
                    <a href={`${event_path(LOCALE, this.props.event_id)}/${micro_app.page_url}`} key={micro_app.id}>
                        <div className='micro_app' key={micro_app.name}>
                            <img src={images[micro_app.icon_url]} alt="micro-app-types icon" className='icon'/>
                            <div className='micro_app_types_info'>
                                <h5>
                                    <FormattedMessage id={`micro_app_types.${micro_app.translation_key}.name`}
                                                      defaultMessage={micro_app.name}/>
                                </h5>
                                <p>
                                    <FormattedMessage id={`micro_app_types.${micro_app.translation_key}.description`}
                                                      defaultMessage={micro_app.description}/>
                                </p>
                            </div>
                            <img src={ArrowIcon}/>
                        </div>
                    </a>
                ))}
            </div>
    )
  }
}

export default observer(MicroAppTypesApp)
