import {
    initializeFaro as coreInit,
    getWebInstrumentations,
    ReactIntegration
} from '@grafana/faro-react'

import {TracingInstrumentation} from '@grafana/faro-web-tracing'

export function initializeFaro() {
    
}
