import React, { FC } from 'react'
import EventSimpleNewModel from '../model/EventSimpleNewModel'
import { observer } from 'mobx-react'
import { FormattedMessage, injectIntl } from 'react-intl'
import CircularLoading from '../../../UI/CircularLoading/CircularLoading'

interface InfoFormProps {
    model: EventSimpleNewModel,
    intl: any
}

const InfoForm: FC<InfoFormProps> = observer(({ model, intl }) => {
  return (

      <React.Fragment>
          <div className='simple_new title mb-3'>
              <h3>
                <FormattedMessage id="simple_event.additional_info_title" defaultMessage="Make sure your guests are aware of the details" />
              </h3>
          </div>
          <div className='simple_new form mb-3 position-relative'>
            <label className='simple_new label mb-1' htmlFor="event_additional_form">
              <FormattedMessage id="simple_event.additional_info_comment" defaultMessage="Add a comment to the invitation" />
            </label>
            <textarea
                onChange={(e) => {
                  model.setAdditionalInfo(e.target.value)
                }}
                className="simple_new input mb-4 rounded-2"
                cols={30} rows={10}
                maxLength={240}
                name='event_additional_form'
                id='event_additional_form'
                placeholder={intl.formatMessage({
                  id: 'simple_event.additional_info_placeholder',
                  defaultMessage: 'Dress code: classic, slippers allowed, entrance from the street'
                })}
                value={model.additionalInfo}>
            </textarea>
            <div className='max_text position-absolute'>
              {`${model.additionalInfo.length}/240 ${intl.formatMessage({
                  id: 'simple_event.symbols_count',
                  defaultMessage: 'characters'
                })}`}
            </div>
          </div>
          <div className='simple_new actions'>
              <button className='main_submit' onClick={() => {
                model.setClicked(true)
                model.sendDataToServer()
              }}>
                  {model.clicked
                    ? <CircularLoading/> :
                    <FormattedMessage id="simple_event.next" defaultMessage="Further" />}
              </button>
          </div>
      </React.Fragment>

  )
})

export default injectIntl(InfoForm)
