import React from 'react'
import { FormattedMessage } from 'react-intl'
import MicroAppTypesApp from './micro_app_types/MicroAppTypesApp'

const EventsItem = (props) => {
  const { item, model, microAppTypes } = props
  return (
        <div className='events__item'>
            <div className="events_container justify-content-between">
                <div className="events__item_img">
                    {item.photoUrl
                      ? <img src={`${item.photoUrl}`} alt="a photo for your event"/>
                      : <div className="empty_img"></div>
                    }
                </div>
                <div className="events__item_info">
                    <div className="events__item_head d-flex justify-content-between align-items-end">
                        <h4 className="events__item_title m-0">{item.title}</h4>
                        <div className="events__item_actions d-flex align-items-center gap-2">
                            <a href={item.editEventPath} className='events__item_edit'/>
                            <div onClick={() => {
                              model.removeEvent(item.id)
                            }} className="events__item_delete"/>
                        </div>
                    </div>
                    <div className="events__item_body d-flex justify-content-between">
                        <div className="events__item_describe row">
                            <div className="events__item_row col d-flex align-items-center">
                                <div className="events__item_col date">
                                    <p>
                                        <FormattedMessage id='spa.date' defaultMessage='Date'/>:
                                    </p>
                                </div>
                                <div className="events__item_col date-info"><p>{item.when}</p></div>
                            </div>
                            <div className="w-100"></div>
                            <div className="events__item_row col d-flex align-items-center">
                                <div className="events__item_col address">
                                    <p>
                                        <FormattedMessage id='spa.address' defaultMessage='Address'/>:
                                    </p>
                                </div>
                                <div className="events__item_col address-info"><p>{item.where}</p></div>
                            </div>
                        </div>
                        <div className="events__item_controll d-flex justify-content-end">
                            <div className="actions">
                                <a href={item.viewEventPath}>
                                    <button className="submit">
                                        <FormattedMessage id='spa.view_event_page' defaultMessage='View event page'/>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MicroAppTypesApp event_id={item.id} microAppTypes={microAppTypes}/>
        </div>
  )
}

export default EventsItem
