import React from 'react'
import ImgEventsAddAvatar from 'images/events_add_avatar.svg'
import EventsAddInputs from './EventsAddInputs'
import EventEditModel from './model/EventEditModel'
import PhotoUpload from '../../UI/PhotoUpload/PhotoUpload.js'
import TranslationProvider from '../../providers/TranslationProvider'
import { injectIntl } from 'react-intl'

export default class EventsEditApp extends React.Component {
  constructor (props) {
    super(props)
    this.model = new EventEditModel(props)
    this.locale = props.locale
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('EventsEditApp mounted')
    }
    if (fbq) {
      fbq('track', 'ViewContent', {
        content_name: 'EventsEditApp mounted'
      })
    }
  }

  render () {
    const EventsAddInputsIntl = injectIntl(EventsAddInputs)

    return (
        <TranslationProvider user_locale={this.locale}>
            <div className="content events w-100 d-flex">
                <div className="form-container d-flex w-100 justify-content-center">
                    <PhotoUpload model={this.model} photo={ImgEventsAddAvatar}></PhotoUpload>
                    <section className="section events my-5">
                    <EventsAddInputsIntl model={this.model} />
                    </section>
                </div>
            </div>
        </TranslationProvider>
    )
  }
}
