import React, { FC } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { vendor_interest_path } from '../../../routes.js.erb'
import VendorInterestsModel from './model/VendorInterestsModel'
import { observer } from 'mobx-react'
import PhoneWithCode from '../../editor/ReactQuill/PhoneWithCode'

interface IVendorInterestsForm {
  locale: string
  model: VendorInterestsModel
}

const VendorInterestsForm: FC<IVendorInterestsForm> = observer(({ intl, locale, model }) => {
  return (
      <form action={vendor_interest_path({ locale })} method="POST">
          <label htmlFor="name_input" className='mb-4'>
              <FormattedMessage
                  id='vendor_interests.form.title'
                  defaultMessage='Submit your application'
              />

          </label>
          <input type="text"
              onClick={() => model.clearNameInputError()}
              className={model.isNameInputEmptyError || model.isErrorField('name') ? 'error' : ''}
              placeholder={
              intl.formatMessage({
                id: 'vendor_interests.form.name_placeholder',
                defaultMessage: 'Name'
              })}
              value={model.nameInput}
              onChange={(e) => model.setNameInput(e.target.value)}
              name="name" id="name_input"/>
          <div className="w-100 mb-3"></div>
          <PhoneWithCode
            countryCode={model.countryCodeInput}
            countryCodeCallback={model.setCountryCodeInput.bind(model)}
            phone={model.phoneInput}
            phoneCallback={model.setPhoneInput.bind(model)}
            clearError={model.clearPhoneInputError.bind(model)}
            phoneClassname={model.isPhoneInputEmptyError || model.isErrorField('phone') ? 'error' : ''}
            countryCodeClassname='vendor_interest_country_code'
          />
          <div className="w-100 mb-3"></div>
          <input type="text"
                 onClick={() => model.clearEmailInputError()}
                 value={model.emailInput}
                 onChange={(e) => model.setEmailInput(e.target.value)}
                 className={model.isEmailInputEmptyError || model.isErrorField('email') ? 'error' : ''}
                 placeholder={
              intl.formatMessage({
                id: 'vendor_interests.form.email',
                defaultMessage: 'Email'
              })} name="email"/>
          <div className="w-100 mb-3"></div>
          <input type="text"
                 value={model.serviceInput}
                 onChange={(e) => model.setServiceInput(e.target.value)}
                 placeholder={
              intl.formatMessage({
                id: 'vendor_interests.form.service_placeholder',
                defaultMessage: 'Your service'
              })} name="service"/>
          <div className="w-100 mb-3"></div>
          <input type="submit" className='black_button' value={
              intl.formatMessage({
                id: 'vendor_interests.form.send_placeholder',
                defaultMessage: 'Send'
              })}
              onClick={() => {
                if (model.nameInput.length === 0) {
                  event?.preventDefault()
                  model.setIsNameInputEmptyError(true)
                }
                if (model.phoneInput.length === 0 && model.emailInput.length === 0) {
                  event?.preventDefault()
                  model.setIsPhoneInputEmptyError(true)
                  model.setIsEmailInputEmptyError(true)
                }
              }}
              />
      </form>
  )
})

export default injectIntl(VendorInterestsForm)
