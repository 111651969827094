import React from 'react'
import TranslationProvider from './providers/TranslationProvider'

const SHOW_TIMES = 20

class AlertsNotices extends React.Component {
  constructor (props) {
    super(props)
    this.locale = props.locale
    this.alert = props.alert
    this.notice = props.notice
    this.state = {
      hidden: false
    }
    this.timeOutId = false
  }

  componentDidMount () {
    this.timeOutId = setTimeout(() => {
      this.setState({
        hidden: true
      })
    }, SHOW_TIMES * 1000)
  }

  componentWillUnmount () {
    if (this.timeOutId) {
      clearTimeout(this.timeOutId)
    }
  }

  render () {
    let containerClass = 'user_messages'
    if (this.state.hidden) {
      containerClass += ' d-none'
    }

    let content
    if (this.notice == null && this.alert != null) {
      content = (
        <p id='alert' className='m-0 alert alert-danger' role='alert'>
          {this.alert}
        </p>
      )
    } else if (this.notice != null && this.alert == null) {
      content = (
        <p id='notice' className='m-0 alert alert-success' role='notice'>
          {this.notice}
        </p>
      )
    }

    return (
      <TranslationProvider user_locale={this.locale}>
        <div className={containerClass}>{content}</div>
      </TranslationProvider>
    )
  }
}

export default AlertsNotices
