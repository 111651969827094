import React, { useRef, useState } from 'react'

const PhotoUpload = ({ photo, model }) => {
  const chosenImg = useRef()
  const [popUpState, setPopUpState] = useState('none')
  const [imgContainerBorder, setImgContainerBorder] = useState('0px')
  let result

  const closeEventsPopup = () => {
    setPopUpState('none')
  }

  const openEventsPopup = () => {
    setPopUpState('flex')
  }

  const uploadPhoto = (e) => {
    e.preventDefault()

    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = function () {
      result = reader.result
      chosenImg.current.setAttribute('src', result)
      setImgContainerBorder('6px')
      setPopUpState('none')
      modelUpload(e.target.files[0])
    }
  }

  const modelUpload = (reader) => {
    model.setPhoto(reader)
  }

  return (
        <React.Fragment>
            <div className="person__image my-5">
                <figure className="image__container m-0" style={{ borderRadius: imgContainerBorder }}>
                    <img ref={chosenImg} src={model.uploadedPhoto ? model.uploadedPhoto : photo} alt='upload a real photo'/>
                </figure>
                <div className="file__upload_btn">
                    <label className="p-0 events photo_btn" onClick={() => openEventsPopup()}></label>
                </div>
            </div>
            <div style={{ display: popUpState }} className="events popup__main align-items-center justify-content-center">
                <div className="popup__menu d-flex flex-column justify-content-center p-4">
                    <div className="popup__head d-flex justify-content-between">
                        <h6>Upload new photo</h6>
                        <span className="close events popup" onClick={closeEventsPopup}></span>
                    </div>
                    <div className="popup__body d-flex align-items-center justify-content-center my-3 p-5">
                        <div className="popup__content">
                            <h6>Please upload a real photo.</h6>
                            <h6>We support JPG, GIF or PNG files.</h6>
                            <div className="actions mt-4">
                                <input type="file" data-direct-upload-url="<%= rails_direct_uploads_url %>" name="image" onChange={uploadPhoto} id="file_image" accept="image/*"/>
                                <label htmlFor="file_image" className="submit">Select file</label>
                            </div>
                        </div>
                    </div>
                    <div className="popup__footer d-flex justify-content-center">
                        <span>If you have any problems with your upload, try using a smaller photo.</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
  )
}

export default PhotoUpload
