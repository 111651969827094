import React, { useState } from 'react'
import BudgetModel from './model/BudgetModel'
import { observer } from 'mobx-react'
import { FormattedMessage, injectIntl } from 'react-intl'

interface IBudgetCategoryEdit {
  model: BudgetModel
  type: 'income' | 'expense'
  currentCategoryId: string
}

const BudgetCategoryEdit: React.FC<IBudgetCategoryEdit> = observer(({ model, type, currentCategoryId, intl }) => {
  const [name, setName] = useState<string>(() => model.getCategoryName(type, currentCategoryId))
  const [expected, setExpected] = useState<number>(() => model.getCategoryExpected(type, currentCategoryId))
  const [actual, setActual] = useState<number>(() => model.getCategoryActual(type, currentCategoryId))

  const deleteHandler = () => {
    model.removeCategory(type, currentCategoryId)
  }

  const cancelEditing = (event: React.MouseEvent) => {
    event.stopPropagation()
    model.setEditing(type, currentCategoryId, false)
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    switch (name) {
      case 'name':
        setName(value)
        break
      case 'expected':
        setExpected(Number(value))
        break
      case 'actual':
        setActual(Number(value))
        break
      default:
        break
    }
  }

  const saveHandler = (event: React.MouseEvent) => {
    const categoryData = {
      name,
      expected: expected || 0,
      actual: actual || 0
    }
    model.changeCategory(type, categoryData, currentCategoryId)

    cancelEditing(event)
  }

  return (
      <React.Fragment>
        <div className='budget__category-edit p-3'>
          <div className='row gap-2 gap-sm-0 row-cols-1 row-cols-sm-3'>
            <div className='col col-12 col-sm-6'>
              <h6 className='mb-2'><FormattedMessage id='budgets.category_name' defaultMessage='Name of category'/></h6>
              <input type="text"
                     name="name"
                     placeholder={
                       intl.formatMessage({
                         id: 'budgets.category_name_placeholder',
                         defaultMessage: 'Enter category name...'
                       })
                     }
                     value={name}
                     onChange={changeHandler}
              />
            </div>
            <div className='col col-12 col-sm-3'>
              <h6 className='mb-2'><FormattedMessage id='budgets.expected' defaultMessage='Expected'/></h6>
              <input type="number"
                     name="expected"
                     placeholder='$'
                     value={expected}
                     onChange={changeHandler}
              />
            </div>
            <div className='col col-12 col-sm-3'>
              <h6 className='mb-2'><FormattedMessage id='budgets.actual' defaultMessage='Actual'/></h6>
              <input type="number"
                     name="actual"
                     placeholder='$'
                     value={actual}
                     onChange={changeHandler}
              />
            </div>
          </div>
          <div className='mh-5 d-none d-sm-flex justify-content-between align-items-center mt-3'>
            <div className='actions d-flex gap-2'>
              <button type="button"
                      className='cancel_btn'
                      onClick={cancelEditing}>
                <FormattedMessage id='spa.cancel' defaultMessage='Cancel'/>
              </button>
              <button className='submit'
                      onClick={saveHandler}>
                <FormattedMessage id='spa.save' defaultMessage='Save'/>
              </button>
            </div>
            <button type="button"
                    className='button__delete'
                    onClick={deleteHandler}>
              <div className='delete__img'></div>
            </button>
          </div>
          <div className='d-flex flex-column gap-2 mt-3 w-100 d-sm-none actions'>
            <button className='submit w-100'
                    onClick={saveHandler}>
              <FormattedMessage id='spa.save' defaultMessage='Save'/>
            </button>
            <div className='d-flex gap-1 w-100'>
              <button type="button"
                      className='cancel_btn w-100'
                      onClick={cancelEditing}>
                <FormattedMessage id='spa.cancel' defaultMessage='Cancel'/>
              </button>
              <button type="button"
                      className='button__delete'
                      onClick={deleteHandler}>
                <div className='delete__img'></div>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
  )
})

export default injectIntl(BudgetCategoryEdit)
