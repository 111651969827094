import React from 'react'
import { FormattedMessage } from 'react-intl'

const LeadsPopUp = ({ notes, model }) => {
  return (
    <div className='lead_popup-content'>
        <div className='lead_popup-notes actions d-flex flex-column gap-3'>
            <FormattedMessage tagName='h2' id='guests.comment' />
            <p>{notes}</p>
            <button onClick={() => model.setIsPopupOpened(false)} className='submit w-100'>
              <FormattedMessage id='spa.close'>
                {text => text}
              </FormattedMessage>
            </button>
        </div>
    </div>
  )
}

export default LeadsPopUp
