import React from 'react'
import EventsItem from './EventsItem'
import { observer } from 'mobx-react'

const EventsList = observer(({ editEventPathTemplate, viewEventPathTemplate, model, microAppTypes }) => {
  const resultedItems = []
  const events = model.events
  events.forEach((event) => {
    resultedItems.push(<EventsItem model={model} microAppTypes={microAppTypes} key={`ei-${event.id}`} item={{
      id: event.id,
      title: event.name,
      photoUrl: event.photoUrl,
      when: event.when,
      where: event.where,
      editEventPath: editEventPathTemplate.replace('---id---', event.id),
      viewEventPath: viewEventPathTemplate.replace('---id---', event.id)
    }}/>)
  })
  return (
        <React.Fragment>
            {resultedItems}
        </React.Fragment>
  )
})

export default EventsList
