import React from 'react'

import en from 'react-intl/locale-data/en'
import de from 'react-intl/locale-data/de'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import ja from 'react-intl/locale-data/ja'
import ko from 'react-intl/locale-data/ko'
import ru from 'react-intl/locale-data/ru'
import ky from 'react-intl/locale-data/ky'
import kk from 'react-intl/locale-data/kk'
import uk from 'react-intl/locale-data/uk'

import { IntlProvider, addLocaleData } from 'react-intl'
import { translations } from '../../i18n/translations'
import { defaultLocale } from '../../i18n/default'

const TranslationProvider = ({ children, user_locale }) => {
  addLocaleData([
    ...en,
    ...de,
    ...es,
    ...fr,
    ...ja,
    ...ko,
    ...ru,
    ...ky,
    ...kk,
    ...uk
  ])

  const locale = user_locale || defaultLocale
  const messages = translations[locale]

  return (
        <IntlProvider locale={locale} key={locale} messages={messages}>
            {children}
        </IntlProvider>
  )
}

export default TranslationProvider
