import React from 'react'

interface GiftRegistryListProps {
    allGiftRegistries: []
}

const GiftRegistryList: React.FC<GiftRegistryListProps> = ({ allGiftRegistries }) => {
  return (
        <React.Fragment>
            {allGiftRegistries.map((registry) => {
              return (
                    <div key={registry.id}>
                        <a href={registry.external_url}>{registry.name}</a>
                    </div>
              )
            })}
        </React.Fragment>
  )
}

export default GiftRegistryList
