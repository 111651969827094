import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import MailSent from '../../../assets/images/mail_guests_sent.svg'
import Warning from '../../../assets/images/warning.svg'
import { FormattedMessage, injectIntl } from 'react-intl'

interface IGuestsModalEnsure {
    model: any
}

const GuestsModalEnsure: FC<IGuestsModalEnsure> = observer(({ model, intl }) => {
  const isModalOpen = model.isModalEnsureOpened
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false)
  const twilioWaFirst = model.twilioWaFirst
  const handleFirstClose = () => {
    setIsClosing(true)
    model.setModalEnsureOpened(false)
    setIsClosing(false)
  }
  const handleSecondClose = () => {
    setIsClosing(true)
    setTimeout(() => {
      setIsSaveClicked(false)
      setIsClosing(false)
    }, 500)
  }
  const handleConfirmation = () => {
    handleFirstClose()
    setIsSaveClicked(true)
    model.serverSendInvites()
  }

  const guestsCount = model.notShippedGuestsCount
  const emailsCount = model.notShippedEmailsCount
  const smsCount = model.notShippedSmsCount

  return (
      <>
      {isSaveClicked
        ? (
          <div className={`modal ${isSaveClicked ? 'show' : ''}`}>
              <div className={`modal-dialog modal-dialog-centered ${isClosing ? 'closing' : ''}`}>
                  <div className='modal-content-custom'>
                      <div className={'img-container'}>
                        <img className={'letter-img'} src={MailSent} alt='mail'/>
                      </div>
                      <div className='invitation-modal-header modal-header-sent'>
                          <h5 className='modal-title'>
                            <FormattedMessage id='guests.modal.sent_title' defaultMessage='Invitations have been sent successfully'/>
                          </h5>
                      </div>
                      <div className='modal-body content-sent'>
                          <p>
                            <FormattedMessage id='guests.modal.sent_content' defaultMessage='You can track the status of received invitations in your profile'/>
                          </p>
                      </div>
                      <div className='modal-footer-custom actions actions-sent'>
                          <button type='button' className='main_submit btn__guest btn-ok' onClick={handleSecondClose}>
                            <FormattedMessage id='guests.modal.ok' defaultMessage='Ok'/>
                          </button>
                      </div>
                  </div>
              </div>
              <div className={`modal-backdrop fade ${isSaveClicked ? 'show' : ''}`}></div>
          </div>
          )
        : (
          <div className={`modal ${isModalOpen ? 'show' : ''}`}>
              <div className={`modal-dialog modal-dialog-centered ${isClosing ? 'closing' : ''}`}>
                  <div className='modal-content-custom'>
                      <div className='invitation-modal-header'>
                          <h5 className='modal-title'>
                            <FormattedMessage id='guests.modal.title' defaultMessage='Confirm sending of invitations'/>
                          </h5>
                      </div>
                      <div className='modal-body'>
                          <p>
                            <FormattedMessage id='guests.total_guests' defaultMessage='Total guests:'/> <span className={'main_heading_brand count-view'}>{guestsCount}</span>
                          </p>
                          <p>
                            <FormattedMessage id='guests.modal.total_emails' defaultMessage='Total emails:'/> <span className={'main_heading_brand count-view'}>{emailsCount}</span>
                          </p>
                          <p>
                            <FormattedMessage id='guests.modal.total_sms' defaultMessage='Total sms:'/> <span className={'main_heading_brand count-view'}>{smsCount}</span>
                          </p>
                      </div>
                      <div className='modal-footer-custom actions'>
                          <button type='button' className='btn_close main_submit btn__guest' onClick={handleFirstClose}>
                            <FormattedMessage id='spa.close' defaultMessage='Close'/>
                          </button>
                          <button type='button' className={`main_submit btn__guest ${guestsCount === 0 ? 'disabled' : ''}`} onClick={handleConfirmation} disabled={guestsCount === 0}>
                            <FormattedMessage id='guests.modal.confirm' defaultMessage='Confirm'/>
                          </button>
                      </div>
                      {twilioWaFirst
                        ?
                          (
                            <div className='modal-warning'>
                              <img src={Warning} alt='warning'/>
                              <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'guests.modal.warning_text', defaultMessage: 'We would attempt to send your invitation <b>through WhatsApp,</b> if your guest <b>does not have WhatsApp account</b> we would send <b>regular text message (SMS)</b>' }) }}>
                              </p>
                            </div>
                          )
                        :
                        null}
                  </div>
              </div>
              <div className={`modal-backdrop fade ${isModalOpen ? 'show' : ''}`}></div>
          </div>
          )}
      </>
  )
})

export default injectIntl(GuestsModalEnsure)
