import { action, makeObservable, observable, reaction } from 'mobx'
import IArticle from '../interfaces/IArticle'
import IArticlesIndexModel from '../interfaces/IArticlesIndexModel'
import { IBrandArticles } from '../interfaces/IBrandArticles'
import IBrands from '../interfaces/IBrands'

interface IArticleIndexModelProps {
  articles: IArticle[];
  brands: IBrands[];
}
class ArticlesIndexModel implements IArticlesIndexModel {
  articles: IArticle[]
  sortedArticles: IArticle[]
  brands: IBrands[]
  brandArticles: IBrandArticles

  constructor ({ articles, brands }: IArticleIndexModelProps) {
    this.articles = articles
    this.sortedArticles = this.articles
    this.brands = brands
    this.brandArticles = {}

    this.sortArticlesByBrand(this.sortedArticles)

    makeObservable(this, {
      articles: observable,
      sortedArticles: observable,
      brandArticles: observable,
      sortArticlesByBrand: action
    })

    reaction(
      () => this.sortedArticles,
      (sortedArticles) => {
        this.sortArticlesByBrand(sortedArticles)
      }
    )
  }

  sortArticlesByBrand (sortedArticles: IArticle[]) {
    for (const brand of this.brands) {
      this.brandArticles[brand.name] = sortedArticles.filter(
        (article: IArticle) => article.brand_id === brand.id
      )
    }
  }
}

export default ArticlesIndexModel
