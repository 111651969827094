import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface IVendorLocations {
    model: any
}

const VendorLocations: FC<IVendorLocations> = ({ model }) => {
  let locationsMarkup
  if (model.vendorInfo.locations.length > 0) {
    locationsMarkup = []
    model.vendorInfo.locations.forEach((location: any) => {
      locationsMarkup.push(<div key={location.translation_key}>
                <FormattedMessage
                    id={`locations.${location.translation_key}`}
                    defaultMessage={location.name_default}/>
            </div>)
    })
  } else {
    locationsMarkup = <div>{model.vendorInfo.serviceAreas}</div>
  }
  return (
        <div className='vendor__info_location'>{locationsMarkup}</div>
  )
}

export default VendorLocations
