import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import TranslationProvider from './providers/TranslationProvider'

class LandingSlider extends Component {
  constructor (props) {
    super(props)
    this.locale = props.locale
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
              {/* On the same level as landing-info inside slider on landing.html.erb
              <div className="landing-slider_switchers d-flex">
                <div className="switcher left"></div>
                <div className="switcher right"></div>
              </div>
              */}
          <div className="landing-slider_body d-flex justify-content-center">
            {this.props.sliderList.map(element => {
              return <a href={element.brand_url_starter} key={element.id}>
                <div className='landing-slide'>
                  <div className={'landing-slide_img ' + element.name}>
                  </div>
                  <div className='w-100 p-4'>
                    <div className="landing-slide_info mb-4">
                      <h6>{element.title}</h6>
                      <p>{element.description}</p>
                    </div>
                    <button className='blue__button w-100'>
                      <FormattedMessage id='get_started-button' defaultMessage='Get Started' />
                    </button>
                  </div>
                </div>
              </a>
            })}
          </div>
        </TranslationProvider>
    )
  }
}

export default LandingSlider
