import { observer } from 'mobx-react'
import React from 'react'
import { injectIntl } from 'react-intl'

const GuestItemEditControlls = observer(({ model, intl }) => {
  return (
  <div className="controll_btns guests d-flex mt-4">
      <div className="actions p-0">
          <input disabled={model.saveButtonDisabled}
                 type="button"
                 className="submit"
                 value={intl.formatMessage({ id: 'spa.save', defaultMessage: 'Save' })}
          onClick={() => model.saveGuestOnServer()}/>
      </div>
      <input type="button"
             value={intl.formatMessage({ id: 'spa.cancel', defaultMessage: 'Cancel' })}
             className="cancel_btn guests mx-3"
        onClick={() => model.navigateToGuestList()}/>
  </div>
  )
})

export default injectIntl(GuestItemEditControlls)
