import React from 'react'
import { FormattedMessage } from 'react-intl'
import SentIcon from '../../../../../assets/images/simple_form/icons/sent_icon.svg'
import BannerIcon from '../../../../../assets/images/simple_form/icons/banner_icon.svg'
import EmailIcon from '../../../../../assets/images/simple_form/icons/email_icon.svg'

const GuestsInfo = () => {
  return (
        <div
            className='events_simple_new simple_info_block w-100'>
            <div className='walkthrough'>
              <h1><FormattedMessage id="simple_event.guest_info.guest_info_heading" defaultMessage="How it works?" /></h1>
              <div className='d-flex flex-column flex-md-row gap-5'>
                <div className='info_step'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h2><FormattedMessage id="simple_event.guest_info.details_title" defaultMessage="1. Details" /></h2>
                    <div className='next_arrow d-none d-md-block'></div>
                  </div>
                  <p><FormattedMessage id="simple_event.guest_info.details_text" defaultMessage="Fill out a simple form about your event" /></p>
                </div>
                <div className='next_arrow mobile d-block d-md-none'></div>
                <div className='info_step'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h2><FormattedMessage id="simple_event.guest_info.guests_list_title" defaultMessage="2. Guest list" /></h2>
                    <div className='next_arrow d-none d-md-block'></div>
                  </div>
                  <p><FormattedMessage id="simple_event.guest_info.guests_list_text" defaultMessage="Make a guest list, with people who gonna get the invitation" /></p>
                </div>
                <div className='next_arrow mobile d-block d-md-none'></div>
                <div className='info_step'>
                  <div className='d-flex'>
                    <h2><FormattedMessage id="simple_event.guest_info.sending_title" defaultMessage="3. Dispatch!" /></h2>
                  </div>
                  <p><FormattedMessage id="simple_event.guest_info.sending_text" defaultMessage="Ready! Send FREE invitations with one click" /></p>
                </div>
              </div>
            </div>
            <div className='numbers_statistics mt-4'>
              <h1><FormattedMessage id="simple_event.guest_info.our_numbers_title" defaultMessage="Our numbers" /></h1>
              <div className='w-100 d-flex flex-column flex-md-row flex-wrap flex-lg-nowrap align-items-center justify-content-center justify-content-lg-start gap-2 gap-md-5'>
                <div className='statistics_block d-flex flex-nowrap align-items-center justify-content-between'>
                  <div className="statistics_title">
                    <h2>1 000+</h2>
                    <p><FormattedMessage id="simple_event.guest_info.invites_statistics_title" defaultMessage="Invitations" /></p>
                  </div>
                  <div className='statistics_img'>
                    <img src={SentIcon} alt="Sent icon" />
                  </div>
                </div>
                <div className='statistics_block d-flex flex-nowrap align-items-center justify-content-between'>
                  <div className="statistics_title">
                    <h2>900+</h2>
                    <p><FormattedMessage id="simple_event.guest_info.events_statistics_title" defaultMessage="Events" /></p>
                  </div>
                  <div className='statistics_img'>
                    <img src={BannerIcon} alt="Banner icon" />
                  </div>
                </div>
                <div className='statistics_block d-flex flex-nowrap align-items-center justify-content-between'>
                  <div className="statistics_title">
                    <h2>100%</h2>
                    <p><FormattedMessage id="simple_event.guest_info.recipients_statistics_title" defaultMessage="Recipients" /></p>
                  </div>
                  <div className='statistics_img'>
                    <img src={EmailIcon} alt="Email icon" />
                  </div>
                </div>
              </div>
            </div>
        </div>
  )
}

export default GuestsInfo
