import React, { FC } from 'react'
import DefaultLogo from '../../../../assets/images/logos/default.svg'
import FuneralLogo from '../../../../assets/images/logos/funeral.svg'
import GirlsNightLogo from '../../../../assets/images/logos/girlsnight.svg'
import KidbdayLogo from '../../../../assets/images/logos/kidbday.svg'
import PokerNightLogo from '../../../../assets/images/logos/pokernight.svg'
import BabyShowerLogo from '../../../../assets/images/logos/babyshower.svg'
import MeetUpLogo from '../../../../assets/images/logos/meetup.svg'
import WeddingLogo from '../../../../assets/images/logos/wedding.svg'
import PartyLogo from '../../../../assets/images/logos/party.svg'
import HoliLogo from '../../../../assets/images/logos/holi.svg'

import { ILogo } from './ILogo'

const Logo: FC<ILogo> = ({ brand_name, logoUrl }) => {
  const brandLogos:{ [key: string]: any } = {
    funeral: FuneralLogo,
    girlsnight: GirlsNightLogo,
    kidbday: KidbdayLogo,
    pokernight: PokerNightLogo,
    babyshower: BabyShowerLogo,
    meetup: MeetUpLogo,
    wedding: WeddingLogo,
    party: PartyLogo,
    holi: HoliLogo
  }

  const logoImg = brandLogos[brand_name] || DefaultLogo

  return (
    <div className='planning__logo'>
      <a href={logoUrl}>
        <div className='d-flex gap-2 align-items-center'>
          <img src={logoImg} alt='event logo'/>
        </div>
      </a>
    </div>
  )
}

export default Logo
