import { action, makeObservable, observable } from 'mobx'
import { LocationCategory, Vendor } from '../types/Vendor.type'
import { search_with_params_vendors_path } from '../../../../routes.js.erb'

interface IVendorSearchModelProps {
    locale: string
    vendors: Vendor[]
    locations: LocationCategory[]
    categories: LocationCategory[]
    location: LocationCategory
    category: LocationCategory
    totalPages: number
    popularVendors: Vendor[]
    currentPage: number
}

class VendorsSearchModel {
  isSortModalOpen: boolean
  isLoading: boolean
  vendors: Vendor[]
  locations: LocationCategory[]
  categories: LocationCategory[]
  location: LocationCategory
  category: LocationCategory
  selectedLocation: string
  selectedCategory: string
  totalPages: number
  currentPage: number
  locale: string
  popularVendors: Vendor[]

  constructor ({ locale, vendors, locations, categories, location, category, totalPages, popularVendors, currentPage }: IVendorSearchModelProps) {
    this.isSortModalOpen = false
    this.isLoading = false
    this.locale = locale

    this.vendors = vendors
    this.locations = locations
    this.categories = categories
    this.location = location
    this.category = category
    this.selectedLocation = this.location.slug || ''
    this.selectedCategory = this.category.slug || ''
    this.totalPages = totalPages
    this.popularVendors = popularVendors
    this.currentPage = currentPage || 1

    makeObservable(this, {
      selectedLocation: observable,
      selectedCategory: observable,
      isSortModalOpen: observable,
      isLoading: observable,
      currentPage: observable,
      setSelectedLocation: action,
      setSelectedCategory: action,
      setIsSortModalOpen: action,
      setIsLoading: action,
      setCurrentPage: action
    })
  }

  setSelectedCategory (category: string) {
    this.selectedCategory = category
  }

  setSelectedLocation (location: string) {
    this.selectedLocation = location
  }

  setIsSortModalOpen (isOpen: boolean) {
    this.isSortModalOpen = isOpen
  }

  setIsLoading (isLoading: boolean) {
    this.isLoading = isLoading
  }

  redirectToNewPage () {
    this.setIsLoading(true)
    location.href = search_with_params_vendors_path(LOCALE, this.selectedLocation, this.selectedCategory)
  }

  setCurrentPage (page: number) {
    this.currentPage = page
    this.setIsLoading(true)
    location.href = search_with_params_vendors_path(LOCALE, this.selectedLocation, this.selectedCategory, { page })
  }
}

export default VendorsSearchModel
