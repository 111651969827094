import classnames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import Quill from 'react-quill'
import { JsonLd } from 'react-schemaorg'
import ReactQuill from '../editor/ReactQuill/ReactQuill'
import { FormattedMessage, injectIntl } from 'react-intl'

interface ToDoEditorProps {
  model: any;
  currentTaskId: any;
  intl: any
}

const ToDoEditor: FC<ToDoEditorProps> = observer(({ currentTaskId, model, intl }) => {
  const editing = model.getEditing(currentTaskId)
  const description = model.getItemDescription(currentTaskId)

  const editorRef = useRef<Quill>(null)

  const cancelEditing = () => {
    model.setEditing(currentTaskId, false)
  }

  const saveDescription = () => {
    model.setItemDescription(
      currentTaskId,
      editorRef?.current?.makeUnprivilegedEditor(
        editorRef?.current?.getEditor())
        .getHTML())

    cancelEditing()
  }

  let content
  if (editing) {
    content = (
      <div>
        <ReactQuill ref={editorRef} value={description} intl={intl}/>
        <button
          type="button"
          className="m-2 btn btn-success"
          onClick={saveDescription}
        >
          <AiOutlineCheck />{' '}
          <span className="button__text m-0 p-0">
            <FormattedMessage id="spa.save" defaultMessage="Save" />
          </span>
        </button>
        <button
          type="button"
          className="m-2 btn btn-danger"
          onClick={cancelEditing}
        >
          <AiOutlineClose />{' '}
          <span className="button__text m-0 p-0">
            <FormattedMessage id="spa.close" defaultMessage="Close" />
          </span>
        </button>
      </div>
    )
  } else {
    const contentClasses = classnames({
      'todo-done-item': model.getDone(currentTaskId),
      'todo-content-tem': true
    })
    content = (
      <div>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Action',
            name: description
          }}
        />
        <div className={contentClasses}>{ReactHtmlParser(description)}</div>
      </div>
    )
  }
  return <div className="w-100">{content}</div>
})

export default injectIntl(ToDoEditor)
