import React from 'react'
import InitialToDoItem from './InitialToDoItem'

const InitialToDoList = ({ initialTodos }) => {
  return (
        <React.Fragment>
            <div className="todo-items-container">
                {initialTodos?.map(item => {
                  return (
                        <div className='drag__wrapper' key={item.id}>
                            <InitialToDoItem description={item.description} />
                  </div>
                  )
                })}
            </div>
        </React.Fragment>
  )
}

export default InitialToDoList
