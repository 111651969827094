import React from 'react'
import { observer } from 'mobx-react'

const PaginationArrow = observer(({ arrow, model }) => {
  return <div onClick={() => {
    if (arrow.side.includes('right') && model.currentPage < model.totalPages) model.setCurrentPage(model.currentPage + 1)
    if (arrow.side.includes('left') && model.currentPage > 1) model.setCurrentPage(model.currentPage - 1)
  }} className={'events__pages_option' + arrow.side + arrow.active}></div>
})

export default PaginationArrow
