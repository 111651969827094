import ReactOnRails from 'react-on-rails'
import 'trix'
import '@rails/actiontext'
import AlertsNotices from '../components/AlertsNotices'
import GuestsApp from '../components/GuestsApp'
import LandingSlider from '../components/LandingSlider'
import MainHeader from '../components/MainHeader'
import LoginApp from '../components/login/LoginApp.tsx'
import SupportButton from '../components/SupportButton'
import ToDoApp from '../components/ToDoApp'
import InitialToDoApp from '../components/initial_todos/InitialToDoApp'
import ArticlesIndex from '../components/blog_articles/index/ArticlesIndex.tsx'
import ArticleShow from '../components/blog_articles/show/ArticleShow.tsx'
import EventsApp from '../components/events/EventsApp'
import EventAcceptInvite from '../components/events/accept_invite/EventAcceptInvite'
import EventsEditApp from '../components/events/edit/EventsEditApp'
import EventsAppNew from '../components/events/show/EventsAppNew'
import GuestsItemEdit from '../components/guests/edit/GuestsItemEdit'
import LangDropDown from '../components/language/LangDropDown'
import EventSimpleNewApp from '../components/events/simple_new/EventSimpleNewApp.tsx'
import BudgetApp from '../components/budget/BudgetApp'
import GiftRegistriesIndexApp from '../components/gift_registries/index/GiftRegistriesIndexApp'
import InfoBlockRoot from '../components/events/simple_new/info_block/InfoBlockRoot'
import SideStockImg from '../components/events/simple_new/SideStockImg'
import VendorInterestsApp from '../components/vendors/interests/VendorInterestsApp.tsx'
import VendorPageApp from '../components/vendors/show/VendorPageApp'
import { initializeFaro } from './observability.js.erb'
import LeadsApp from '../components/LeadsApp'
import VendorsApp from '../components/vendors/index/VendorsApp'
import VendorsSearchApp from '../components/vendors/search/VendorsSearchApp'
import VendorsOnboardingApp from '../components/vendors/onboarding/VendorsOnboardingApp'
import VendorSearchLanding from '../components/vendors/search/VendorSearchLanding'

initializeFaro()

ReactOnRails.register({
  LandingSlider,
  SupportButton,
  MainHeader,
  AlertsNotices,
  ToDoApp,
  InitialToDoApp,
  EventAcceptInvite,
  BudgetApp,
  LeadsApp,
  'Login::Loginapp': LoginApp,
  'Events::Edit::Eventseditapp': EventsEditApp,
  EventsAppNew,
  LangDropDown,
  'Events::Eventsapp': EventsApp,
  GuestsApp,
  'Guests::Edit::Guestsitemedit': GuestsItemEdit,
  'Events::AcceptInvite::Eventacceptinvite': EventAcceptInvite,
  'Events::SimpleNew::Eventsimplenewapp': EventSimpleNewApp,
  'BlogArticles::Show::Articleshow': ArticleShow,
  'BlogArticles::Index::Articlesindex': ArticlesIndex,
  GiftRegistriesIndexApp,
  'Events::SimpleNew::InfoBlock::Infoblockroot': InfoBlockRoot,
  'Events::SimpleNew::Sidestockimg': SideStockImg,
  'Vendors::Interests::Vendorinterestsapp': VendorInterestsApp,
  'Vendors::Show::Vendorpageapp': VendorPageApp,
  'Vendors::Index::Vendorsapp': VendorsApp,
  'Vendors::Search::Vendorssearchapp': VendorsSearchApp,
  'Vendors::Onboarding::Vendorsonboardingapp': VendorsOnboardingApp,
  'Vendors::Search::Vendorsearchlanding': VendorSearchLanding
})
