import { observer } from 'mobx-react'
import React from 'react'
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import FuneralInputs from './form/FuneralInputs'
// import GirlsnightInputs from './form/GirlsnightInputs'
// import KidbdayInputs from './form/KidbdayInputs'
// import MeetupInputs from './form/MeetupInputs'
// import PokernightInputs from './form/PokernightInputs'
// import BabyshowerInputs from './form/BabyshowerInputs'
import { FormattedMessage, injectIntl } from 'react-intl'

const EventsAddInputs = observer(({ model, intl }) => {
  const addInfoHandler = (e) => {
    model.setAdditionalInfo(e.target.value)
  }

  const cancelHandler = () => {
    history.back()
  }

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    switch (name) {
      case 'name':
        model.setName(value)
        break
      case 'location':
        model.setLocation(value)
        break
      case 'when':
        model.setWhen(value)
        break
      default:
        break
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    model.checkDataValid()
    if (!model.isError) {
      model.saveDataOnBackend()
    }
  }

  /* const eventInputsMap = {
    funeral: <FuneralInputs model={model} handleChange={handleChange} />,
    kidbday: <KidbdayInputs model={model} handleChange={handleChange} />,
    girlsnight: <GirlsnightInputs model={model} handleChange={handleChange} />,
    pokernight: <PokernightInputs model={model} handleChange={handleChange} />,
    meetup: <MeetupInputs model={model} handleChange={handleChange} />,
    babyshower: <BabyshowerInputs model={model} handleChange={handleChange} />
  } */

  return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                {/* eventInputsMap[model.brandId] */}
                <div className="funeral__info events">
                    <h4 className="mb-3">
                        <FormattedMessage id='spa.event_information' defaultMessage='Event information'/>
                    </h4>
                    <div className="funeral__info input events row">
                        <div className="col input__container p-0">
                            <input
                                type="text"
                                onChange={handleChange}
                                name="name"
                                className="inputs w-100"
                                placeholder={
                                  intl.formatMessage({
                                    id: 'spa.name',
                                    defaultMessage: 'Name'
                                  })
                                }
                                value={model.name}
                            />
                        </div>
                        <div className="line_bareer w-100 my-3"></div>
                        <div className="col input__container p-0">
                            <input
                                type="text"
                                onChange={handleChange}
                                name="location"
                                className="inputs w-100"
                                placeholder={
                                  intl.formatMessage({
                                    id: 'spa.where',
                                    defaultMessage: 'Where'
                                  })
                                }
                                value={model.location}
                            />
                        </div>
                        <div className="line_bareer w-100 my-3"></div>
                        <div className="col input__container p-0">
                            <input
                                type="text"
                                onChange={handleChange}
                                name="when"
                                className="inputs w-100"
                                placeholder={
                                  intl.formatMessage({
                                    id: 'spa.when',
                                    defaultMessage: 'When'
                                  })
                                }
                                value={model.when}
                            />
                        </div>
                        {/* <div className="calendar cal-1 col w-100">
                            <div className="calendar__input nonactive d-flex align-items-center">
                                <DatePicker
                                  selected={model.dateOfEvent}
                                  onChange={model.setDateOfEvent.bind(model)}
                                  placeholderText={intl.formatMessage({ id: 'spa.eventDate', defaultMessage: 'Event Date' })}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                            </div>
                        </div>
                        <div className="calendar cal-2 col w-100">
                            <div className="calendar__input nonactive d-flex align-items-center">
                                <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    selected={model.timeOfEvent}
                                    onChange={model.setTimeOfEvent.bind(model)}
                                    placeholderText={intl.formatMessage({ id: 'spa.eventTime', defaultMessage: 'Event Time' })}
                                />
                            </div>
                        </div> */}
                        <div className="line_bareer w-100 my-3"></div>
                        <div className="col input__container description p-0">
                            <textarea
                                onChange={addInfoHandler}
                                className="inputs_d w-100"
                                id="description"
                                cols="30" rows="10"
                                placeholder={intl.formatMessage({ id: 'spa.additionalInfo', defaultMessage: 'Additional Information' })}
                                value={model.additionalInfo}>
                                </textarea>
                        </div>
                    </div>
                </div>
                <div className='form_errors d-flex flex-column'>
                    {model.errorInputs.nameError.errorState && <div className='field__error w-25'>{model.errorInputs.nameError.errorMessage}</div>}
                </div>
                <div className="controll_btns events d-flex mt-5">
                    <div className="actions p-0">
                        <input type="submit" disabled={model.loading || model.isError} className="submit"
                               value={intl.formatMessage({ id: 'spa.save', defaultMessage: 'Save' })}/>
                    </div>
                    <input type="button" disabled={model.loading} onClick={cancelHandler}
                           value={intl.formatMessage({ id: 'spa.cancel', defaultMessage: 'Cancel' })}
                           className="cancel_btn events mx-5"/>
                </div>
            </form>
        </React.Fragment>
  )
})

export default injectIntl(EventsAddInputs)
