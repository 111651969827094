import React from 'react'
import BudgetCategoryEdit from './BudgetCategoryEdit'
import { observer } from 'mobx-react'

interface IBudgetCategory {
  model: any
  currentCategoryId: any
  type: 'income' | 'expense'
}

const BudgetCategory: React.FC<IBudgetCategory> = observer(({ model, type, currentCategoryId }) => {
  const colEditorRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event: any) => {
    if (colEditorRef.current && !colEditorRef.current.contains(event.target)) {
      cancelEditing(event as React.MouseEvent)
    }
  }
  const cancelEditing = (event: React.MouseEvent) => {
    event.stopPropagation()
    model.setEditing(type, currentCategoryId, false)
  }

  const startEditingArea = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (!model.getEditing(type, currentCategoryId)) {
      model.setEditing(type, currentCategoryId, true)
    }
  }

  const name = model.getCategoryName(type, currentCategoryId)
  const expected = model.getCategoryExpected(type, currentCategoryId)
  const actual = model.getCategoryActual(type, currentCategoryId)

  return (
      <div ref={colEditorRef}>
        <div className='budget__category d-flex justify-content-between align-items-center gap-4'>
          <div onClick={startEditingArea} className='w-100'>
            <h3>{name}</h3>
            <div className='budget__bar w-100'>
              <div className='budget__bar-value'></div>
            </div>
          </div>
          <div>
            <div className='d-flex align-items-center gap-1'>
              <span className='category__budget'>${expected}</span>
              <span onClick={startEditingArea} className='edit__button'></span>
            </div>
            <div>
              <span className='category__leftover'>${actual}</span>
            </div>
          </div>
        </div>
        {model.getEditing(type, currentCategoryId) ?
            (
                <div className="col py-4 d-flex align-items-center col-editor">
                  <BudgetCategoryEdit model={model} type={type} currentCategoryId={currentCategoryId} />
                </div>
            ) :
            (
                <div onClick={startEditingArea} className="col py-4 d-flex align-items-center col-editor">
                </div>
            )
        }
      </div>
  )
})

export default BudgetCategory
