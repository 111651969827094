import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

class InitialToDoItem extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const itemStyleClasses = 'item my-3 mx-0 px-3' + 'col-2 py-4 d-flex align-items-center'
    return (
            <React.Fragment>
                <div className={itemStyleClasses}>
                    <p className='description'>{ReactHtmlParser(this.props.description)}</p>
                </div>
            </React.Fragment>
    )
  }
}

export default InitialToDoItem
