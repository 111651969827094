import { action, observable, makeObservable } from 'mobx'

interface IRemainingTime {
    total: number
    days: string
    hours: string
    minutes: string
    seconds: string
}

interface IVendorInterestError {
  key: string
  error: string
}

class VendorInterestsModel {
  endtime: number
  remainingTime: IRemainingTime | object
  nameInput: string
  phoneInput: string
  countryCodeInput: string
  emailInput: string
  serviceInput: string
  isNameInputEmptyError: boolean
  isPhoneInputEmptyError: boolean
  isEmailInputEmptyError: boolean
  vendorInterestErrors: IVendorInterestError[]

  constructor ({ errors, vendorInterestFields }: { errors: IVendorInterestError[], vendorInterestFields: any }) {
    this.endtime = new Date('2024-08-01').getTime()
    this.remainingTime = {
      total: 0,
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
    this.nameInput = vendorInterestFields.name || ''
    this.phoneInput = vendorInterestFields.phone || ''
    this.countryCodeInput = vendorInterestFields.countryCode || '+1'
    this.emailInput = vendorInterestFields.email || ''
    this.serviceInput = vendorInterestFields.service || ''
    this.isNameInputEmptyError = false
    this.isPhoneInputEmptyError = false
    this.isEmailInputEmptyError = false
    this.vendorInterestErrors = errors

    makeObservable(this, {
      remainingTime: observable,
      nameInput: observable,
      phoneInput: observable,
      countryCodeInput: observable,
      emailInput: observable,
      serviceInput: observable,
      vendorInterestErrors: observable,
      isNameInputEmptyError: observable,
      isPhoneInputEmptyError: observable,
      isEmailInputEmptyError: observable,
      setNameInput: action,
      setPhoneInput: action,
      setCountryCodeInput: action,
      setEmailInput: action,
      setServiceInput: action,
      setVendorInterestErrors: action,
      isErrorField: action,
      setIsNameInputEmptyError: action,
      setIsPhoneInputEmptyError: action,
      setIsEmailInputEmptyError: action,
      setRemainingTime: action
    })
  }

  setNameInput (input: string) {
    this.nameInput = input
  }

  setPhoneInput (input: string) {
    this.phoneInput = input
  }

  setCountryCodeInput (input: string) {
    this.countryCodeInput = '+' + input
  }

  setEmailInput (input: string) {
    this.emailInput = input
  }

  setServiceInput (input: string) {
    this.serviceInput = input
  }

  setVendorInterestErrors (errors: IVendorInterestError[]) {
    this.vendorInterestErrors = errors
  }

  isErrorField (key: string) {
    return this.vendorInterestErrors.some((er: IVendorInterestError) => er.key === key)
  }

  setIsNameInputEmptyError (isEmpty: boolean) {
    this.isNameInputEmptyError = isEmpty
  }

  setIsPhoneInputEmptyError (isEmpty: boolean) {
    this.isPhoneInputEmptyError = isEmpty
  }

  setIsEmailInputEmptyError (isEmpty: boolean) {
    this.isEmailInputEmptyError = isEmpty
  }

  clearVendorInterestError (key: string) {
    this.vendorInterestErrors = this.vendorInterestErrors.filter(error => error.key !== key)
  }

  clearNameInputError () {
    this.setIsNameInputEmptyError(false)
    this.clearVendorInterestError('name')
  }

  clearPhoneInputError () {
    this.setIsPhoneInputEmptyError(false)
    this.setIsEmailInputEmptyError(false)
    this.clearVendorInterestError('phone')
  }

  clearEmailInputError () {
    this.setIsEmailInputEmptyError(false)
    this.setIsPhoneInputEmptyError(false)
    this.clearVendorInterestError('email')
  }

  setRemainingTime () {
    let days, hours, minutes, seconds
    const total = this.endtime - new Date().getTime()
    const days_number = Math.floor(total / (1000 * 60 * 60 * 24))
    const hours_number = Math.floor((total / (1000 * 60 * 60)) % 24)
    const minutes_number = Math.floor((total / 1000 / 60) % 60)
    const seconds_number = Math.floor((total / 1000) % 60)

    if (days_number < 10) {
      days = `0${days_number}`
    } else {
      days = days_number
    }

    if (hours_number < 10) {
      hours = `0${hours_number}`
    } else {
      hours = hours_number
    }

    if (minutes_number < 10) {
      minutes = `0${minutes_number}`
    } else {
      minutes = minutes_number
    }

    if (seconds_number < 10) {
      seconds = `0${seconds_number}`
    } else {
      seconds = seconds_number
    }

    this.remainingTime = {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  }
}

export default VendorInterestsModel
