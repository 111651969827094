import { action, computed, makeObservable, observable } from 'mobx'

class EventEditModel {
  brandId
  name
  description
  location
  id
  photo
  loading
  dateOfBirth
  dateOfDeath
  dateOfEvent
  timeOfEvent
  when
  additionalInfo
  errorInputs

  constructor (initialData) {
    const { userInput, userPhoto, id, serverPath, brandId } = initialData
    this.brandId = brandId || 'funeral'
    this.name = ''
    this.description = ''
    this.location = ''
    this.photo = false
    this.loading = false
    this.id = id | false
    this.serverPath = serverPath
    this.dateOfBirth = ''
    this.dateOfDeath = ''
    this.dateOfEvent = ''
    this.timeOfEvent = ''
    this.uploadedPhoto = ''
    this.additionalInfo = ''
    this.when = ''

    this.isError = false
    this.errorInputs = {
      nameError: { errorState: false, errorMessage: 'Name field is a required field!' }
    }

    if (userInput) {
      this.name = userInput.name || ''
      this.description = userInput.description
      if (userInput.location) {
        this.location = userInput.location
      }
      if (userInput.when) {
        this.when = userInput.when
      }
      if (userInput.dateOfBirth) {
        this.dateOfBirth = new Date(userInput.dateOfBirth)
      }
      if (userInput.dateOfDeath) {
        this.dateOfDeath = new Date(userInput.dateOfDeath)
      }

      if (userInput.dateOfEvent) {
        this.dateOfEvent = new Date(userInput.dateOfEvent)
      }
      if (userInput.timeOfEvent && userInput.timeOfEvent.length > 0) {
        this.timeOfEvent = userInput.timeOfEvent
      }

      if (userInput.dateOfEvent && userInput.timeOfEvent) {
        const utcDateTime = new Date(userInput.dateOfEvent + 'T' + userInput.timeOfEvent)

        this.dateOfEvent = utcDateTime
        this.timeOfEvent = utcDateTime
      }

      this.additionalInfo = userInput.additionalInfo
    }

    if (userPhoto) {
      this.uploadedPhoto = userPhoto
    }

    makeObservable(this, {
      brandId: observable,
      name: observable,
      description: observable,
      location: observable,
      when: observable,
      dateOfBirth: observable,
      dateOfDeath: observable,
      dateOfEvent: observable,
      timeOfEvent: observable,
      loading: observable,
      isError: observable,
      additionalInfo: observable,
      setLoading: action,
      setDescription: action,
      setLocation: action,
      setWhen: action,
      setDateOfBirth: action,
      setDateOfDeath: action,
      setDateOfEvent: action,
      setTimeOfEvent: action,
      setAdditionalInfo: action,
      checkDataValid: action,
      checkName: action,
      serverPayload: computed,
      localTimeZone: computed
    })
  }

  setName (newName) {
    this.name = newName
    this.checkName()
  }

  checkName () {
    if (this.name.length === 0) {
      this.errorInputs.nameError.errorState = true
      this.isError = true
    } else {
      this.errorInputs.nameError.errorState = false
      this.isError = false
    }
  }

  checkDataValid () {
    this.checkName()
  }

  setDescription (newDescription) {
    this.description = newDescription
  }

  setLocation (newLocation) {
    this.location = newLocation
  }

  setWhen (newWhen) {
    this.when = newWhen
  }

  setPhoto (newPhoto) {
    this.photo = newPhoto
  }

  setDateOfBirth (newDOB) {
    this.dateOfBirth = newDOB
  }

  setDateOfDeath (newDOD) {
    this.dateOfDeath = newDOD
  }

  setDateOfEvent (newDOE) {
    this.dateOfEvent = newDOE
  }

  setTimeOfEvent (newTOE) {
    this.timeOfEvent = newTOE
  }

  setLoading (newLoading) {
    this.loading = newLoading
  }

  setAdditionalInfo (newAddInfo) {
    this.additionalInfo = newAddInfo
  }

  get localTimeZone () {
    const time_offset = this.timeOfEvent.getTimezoneOffset()
    let time_zone = 'UTC-0'

    if (time_offset < 0) {
      time_zone = `UTC+${time_offset / -60}`
    } else if (time_offset > 0) {
      time_zone = `UTC-${time_offset / 60}`
    }

    return time_zone
  }

  get serverPayload () {
    const data = new FormData()
    if (this.photo) {
      data.append('image', this.photo)
    }

    const clientData = {
      name: this.name,
      description: this.description,
      location: this.location,
      dateOfBirth: this.dateOfBirth,
      dateOfDeath: this.dateOfDeath,
      when: this.when,
      additionalInfo: this.additionalInfo
    }

    data.append(
      'client_data',
      JSON.stringify(clientData)
    )

    return data
  }

  saveDataOnBackend () {
    const that = this
    this.setLoading(true)
    const csrfToken = document.querySelector("[name='csrf-token']").content
    let HTTP_METHOD = 'POST'
    if (this.id) {
      HTTP_METHOD = 'PUT'
    }

    fetch(this.serverPath, {
      method: HTTP_METHOD,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      body: that.serverPayload
    })
      .then((response) => {
        if (response.redirected) {
          this.followRedirect(response)
        } else {
          return response.json()
        }
      })
      .then(that.redirectToServerPage.bind(that))
      .finally(() => {
        that.setLoading(false)
      })
  }

  redirectToServerPage (response) {
    location.href = response.showPath
  }

  followRedirect (response) {
    location.href = response.url
  }
}

export default EventEditModel
