import React, { Component } from 'react'

class SupportButton extends Component {
  redirectUser () {
    location.href = '/article/planning-how-walk-through'
  }

  render () {
    return (
        <div>
            {/* <div onClick={this.redirectUser} className="support__button">
              <div className="logo"></div>
              <span>Help</span>
          </div> */}
        </div>
    )
  }
}

export default SupportButton
