import React from 'react'
import ToDoListModel from './todolist/model/ToDoListModel'
import ToDoList from './todolist/ToDoList'
import ToDoHeader from './todolist/ToDoHeader'
import TranslationProvider from './providers/TranslationProvider'

class ToDoApp extends React.Component {
  constructor (props) {
    super(props)
    this.model = new ToDoListModel(
      props.server_url,
      props.haveInitialTemplate
    )
    this.scrollViewRef = React.createRef(null)
  }

  componentDidMount () {
    this.model.fetchDataFromServer()
  }

  render () {
    return (
            <div className="todo-app-container">
                <TranslationProvider user_locale={LOCALE}>
                    <React.Fragment>
                        <ToDoHeader scrollViewRef={this.scrollViewRef} model={this.model}/>
                        <ToDoList model={this.model}/>
                        <div ref={this.scrollViewRef} className='hidden__scroll_view'></div>
                    </React.Fragment>
                </TranslationProvider>
            </div>
    )
  }
}

export default ToDoApp
