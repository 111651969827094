import React, { forwardRef } from 'react'
import Quill from 'react-quill'

interface IReactQuill {
  value: string,
  onChange?: (content: string) => void,
  intl: any,
}

const ReactQuill = forwardRef<Quill, IReactQuill>(({ value, onChange, intl }, ref) => {
  const Quill = typeof window === 'object' ? require('react-quill') : () => <div className='react-quill_empty'>{value}</div>

  return (
      <React.Fragment>
        <Quill ref={ref} theme="snow" placeholder={intl.formatMessage({ id: 'spa.description', defaultMessage: 'Description' })} value={value} onChange={onChange} />
      </React.Fragment>
  )
})

ReactQuill.displayName = 'ReactQuill'

export default ReactQuill
