import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface IVendorSearchFormProps {
    model: any
}

const VendorSearchForm: FC<IVendorSearchFormProps> = observer(({ model }) => {
  return (
        <div className="action_block d-flex flex-wrap align-items-center justify-content-center gap-3 mt-5">
            <select
                onChange={(e) => model.setSelectedCategory((e.target as HTMLSelectElement).value)}
                value={model.selectedCategory}
                className='search_select'
            >
                {model.categories.map((category: any) => (
                    <option value={category.slug} key={category.id}>
                        <FormattedMessage id={`vendors.categories.${category.translation_key}.one`}
                                            defaultMessage={category.name_default}>
                            {(text: string) => text}
                        </FormattedMessage>
                    </option>
                ))}
            </select>
            <select
                onChange={(e) => model.setSelectedLocation((e.target as HTMLSelectElement).value)}
                value={model.selectedLocation}
                className='search_select'
            >
                {model.locations.map((location: any) => (
                    <option value={location.slug} key={location.id}>
                        <FormattedMessage id={`locations.${location.translation_key}`}
                                            defaultMessage={location.name_default}>
                            {(text: string) => text}
                        </FormattedMessage>
                    </option>
                ))}
            </select>
            <button onClick={() => model.redirectToNewPage()} className='black_button'>Поиск</button>
        </div>
  )
})

export default VendorSearchForm
