import React, { FC } from 'react'
import { observer } from 'mobx-react'
import VendorServiceItem from './VendorServiceItem'
import Pagination from '../pagination/Pagination'
import { Vendor } from './types/Vendor.type'
import CircularLoading from '../../UI/CircularLoading/CircularLoading'

interface IVendorsServiceListProps {
    model: any
}

const VendorsServiceList: FC<IVendorsServiceListProps> = observer(({ model }) => {
  return (
        <div className='search_service_list mt-5'>
            {model.vendors.map((vendor: Vendor, index: number) => (
                <VendorServiceItem locale={model.locale} key={index} vendor={vendor} location={model.location}/> // TODO: isPromo атрибут boolean для отображения Промо
            ))}
            <Pagination model={model} />
            {model.isLoading &&
                <div className='loading_main'>
                    <CircularLoading />
                </div>
            }
        </div>
  )
})

export default VendorsServiceList
