import React, { FC } from 'react'
import EventFallbackStockImg from '../../../../assets/images/simple_form/default-fallback-image.png'
import EventStockImg from '../../../../assets/images/simple_form/simple_stock_event_img.png'

interface SideStockImgProps {
    brandName: string
}

const SideStockImg: FC<SideStockImgProps> = ({ brandName }) => {
  return (
      <React.Fragment>
          <div className='simple_new image'>
              <img src={brandName === 'wedding'
                ? EventStockImg
                : EventFallbackStockImg
              } alt="Event image"/>
          </div>
      </React.Fragment>
  )
}

export default SideStockImg
