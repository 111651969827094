import React, { Component } from 'react'
import LangDropDownSelect from './LangDropDownSelect'
import LangDropDownModel from './model/LangDropDownModel'
import { ILangDropDownModel } from './types/LangDropDownModel.interface'

interface ILangDropDown {
  languages: string[]
  locale: string
}

class LangDropDown extends Component<ILangDropDown> {
  languages: string[]
  defaultLanguage: string
  model: ILangDropDownModel

  constructor (props: ILangDropDown) {
    super(props)

    this.languages = props.languages || ['en']

    this.defaultLanguage = props.locale || 'en'

    this.model = new LangDropDownModel({
      languages: this.languages,
      selectedLanguage: this.defaultLanguage
    })
  }

  render () {
    return (
      <div className="lang_menu">
        <LangDropDownSelect model={this.model}></LangDropDownSelect>
      </div>
    )
  }
}

export default LangDropDown
