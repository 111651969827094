import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const GuestItemEditInput = observer(({ model, type, intl }) => {
  const addGuest = <FormattedMessage id='guests.info_add_header' defaultMessage='Add guest information'/>
  const editGuest = <FormattedMessage id='guests.info_edit_header' defaultMessage='Edit guest information'/>
  const translationGuest = (type === 'Add') ? addGuest : editGuest
  return (
            <React.Fragment>
                {model.errorStatus &&
                    <div className="error__block mb-4">
                        <p className='error__block_text m-0'>{model.errorStatus}</p>
                    </div>
                }
                <div className='information d-flex flex-column align-items-center'>
                    <div className='information__text'>
                        <h2>
                            {translationGuest}
                        </h2>
                        <p>
                            <FormattedMessage id='guests.info_tip'
                                              defaultMessage='Please provide information about your guests'/>
                        </p>
                    </div>
                    <div className='row information__form justify-content-center align-items-center'>
                        <div className='input__container col name'>
                            <input onChange={(event) => model.changeGuestUserName(event.target.value)}
                                   value={model.guestUserInput.name}
                                   placeholder={intl.formatMessage({ id: 'guests.name', defaultMessage: 'Name' })}
                                   type="text"/>
                        </div>
                        <div className="w-100 mt-4"></div>
                        <div className='information__text alerted p-0 m-0'>
                            <h2>
                                <FormattedMessage id='spa.guest_info' defaultMessage='Guest contact info'/>
                            </h2>
                        </div>
                        <div className="w-100"></div>
                        <div
                            className={`input__container mail d-flex align-items-center validation-${model.emailErrorActive}`}>
                            <input onChange={(event) => model.changeGuestUserEmail(event.target.value)}
                                   value={model.guestUserInput.email}
                                   placeholder={intl.formatMessage({
                                     id: 'user_session.email_placeholder',
                                     defaultMessage: 'Email'
                                   })}
                                   type="email"/>
                        </div>
                        <div className="w-100"></div>
                        <div className='p-0'>
                            <div className='d-flex gap-2'>
                                <select className='tel__code_select' name="guest_tel_code"
                                        onChange={(event) => model.changeGuestCountryCode(event.target.value)}
                                        value={model.guestUserInput.countryCode}>
                                    <option value="+1">+1</option>
                                    <option value="+996">+996</option>
                                    <option value="+998">+998</option>
                                    <option value="+7">+7</option>
                                    <option value="+353">+353</option>
                                    <option value="+380">+380</option>
                                    <option value="+44">+44</option>
                                    <option value="+61">+61</option>
                                </select>
                                <div
                                    className={`input__container phone d-flex align-items-center validation-${model.phoneErrorActive}`}>
                                    <input onChange={(event) => model.changeGuestUserPhone(event.target.value)}
                                           value={model.guestUserInput.phone}
                                           placeholder={intl.formatMessage({
                                             id: 'user_session.phone_number_placeholder',
                                             defaultMessage: 'Phone Number'
                                           })}
                                           type="tel"/>
                                </div>
                            </div>
                            <div className="w-100"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
  )
}
)

export default injectIntl(GuestItemEditInput)
