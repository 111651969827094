import { observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PhoneWithCode from '../editor/ReactQuill/PhoneWithCode'

const GuestsItemMock = observer(({ model, intl }) => {
  const isReadyToSave = model.mockGuestData.isReadyToSave

  return (
        <div className={`guests__item row-md ${isReadyToSave ? 'guests__item__edit' : ''}`}>
            <div className="guests__item_fields row md-p-0 gap-2">
                <div className="guests__item_col col-2 name d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.name' defaultMessage='Name'/>
                    </div>
                    <input
                        type="text"
                        value={model.mockGuestData.name}
                        onChange={(e) => model.setMockName(e.target.value)}
                        className="input__edit m-0"
                        placeholder={intl.formatMessage({
                          id: 'guests.name',
                          defaultMessage: 'Name'
                        })}
                    />
                </div>

                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col contact d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.email' defaultMessage='Email'/>
                        <span className='optional_flag ms-1'>
                            <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                        </span>
                    </div>
                    <div
                        className={`guests__item_mail d-flex align-items-center validation-${model.mockGuestData.emailErrorActive}`}>
                        <input
                            type="email"
                            value={model.mockGuestData.email}
                            onChange={(e) => model.setMockEmail(e.target.value)}
                            className="input__edit m-0"
                            placeholder={intl.formatMessage({
                              id: 'guests.email',
                              defaultMessage: 'Email'
                            })}
                        />
                    </div>
                </div>
                <div className="w-100 line__mobile d-none"></div>
                <div className="guests__item_col col contact d-flex align-items-sm-center flex-wrap">
                    <div className="d-none info__text">
                        <FormattedMessage id='guests.phone' defaultMessage='Phone number'/>
                        <span className='optional_flag ms-1'>
                            <FormattedMessage id='spa.optional_flag' defaultMessage='(optional)'/>
                        </span>
                    </div>
                    <div className="guests__item_phone d-flex align-items-center gap-2">
                        <PhoneWithCode
                          countryCode={model.mockGuestData.countryCode}
                          countryCodeCallback={model.setMockCountryCode.bind(model)}
                          phone={model.mockGuestData.phone}
                          phoneCallback={model.setMockPhone.bind(model)}
                          phoneClassname='input__edit m-0'
                          readOnly={false}
                        />
                    </div>
                </div>
                <div className="guests__item_col col-2">
                </div>
                <div className="guests__item_col col-2 controlls d-flex justify-content-start align-items-center">
                    {isReadyToSave
                        && (
                            <div className='actions'>
                                <button className='button_accept submit' onClick={() => model.addGuestOnServer()}>
                                    <FormattedMessage id='spa.save' defaultMessage='Save'/>
                                </button>
                                <button
                                    className='button_accept cancel_btn'
                                    onClick={() => model.clearMockInput()}>
                                    <FormattedMessage id='spa.cancel' defaultMessage='Cancel'/>
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
  )
})

export default injectIntl(GuestsItemMock)
