import React from 'react'
import { FormattedMessage } from 'react-intl'

const EventInviteInfo = ({ currentEventInfo, photoUrl }) => {
  return (
        <div className='accept__invite-infocard row row-cols-md-2 row-cols-1'>
            <div className='photo col-md-4 col p-0'>
                <div className="image__container">
                    {photoUrl
                      ? <img src={`${photoUrl}`} alt="a photo for your event"/>
                      : <div className="empty_img"></div>
                    }
                </div>
            </div>
            <div className='content col-md-8 col'>
                <div className='content__box'>
                    <h3 className='mb-4'>{currentEventInfo.name}</h3>
                    <div>
                        <div className="row mb-3">
                            <div className='date event__info_title col-4 d-flex align-items-center'>
                                <FormattedMessage id='spa.date' defaultMessage='Date'/>:</div>
                            <div className='col-8 event__info_desciption'>
                                <p>{currentEventInfo.when}</p>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='address event__info_title col-4 d-flex align-items-center'>
                                <FormattedMessage id='spa.address' defaultMessage='Address'/>:</div>
                            <div className='col-8 event__info_desciption'>
                                <p>{currentEventInfo.location}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default EventInviteInfo
