import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import MainImg from '../../../../assets/images/vendors/services_landing_stock/main_img.jpg'
import MapImg from '../../../../assets/images/vendors/services_landing_stock/map.png'
import LandingSearch from '../../../../assets/images/vendors/services_landing_stock/icons/landing_search.svg'
import LandingInfo from '../../../../assets/images/vendors/services_landing_stock/icons/landing_info.svg'
import LandingChat from '../../../../assets/images/vendors/services_landing_stock/icons/landing_chat.svg'
import PopularServicesList from './popular_services/PopularServicesList'
import PopularServicesItem from './popular_services/PopularServicesItem'
// import ServicesDealsList from './services_deals/SevicesDealsList'
import TranslationProvider from '../../providers/TranslationProvider'
import VendorsLandingModel from './model/VendorsLandingModel'
import VendorLandingForm from './form/VendorLandingForm'
import { LocationCategory } from './types/Vendor.type'
import { FormattedMessage } from 'react-intl'

interface VendorSearchLandingProps {
    locations: LocationCategory[]
    categories: LocationCategory[]
    currentLocation: LocationCategory
    currentCategory: LocationCategory
    popularVendors: Array<any>,
    locationSlug: string,
    categorySlug: string,
    faqs: Array<any>,
    mapsAPIKey: string
    vendorsCount: string
}

export default class VendorSearchLanding extends Component<VendorSearchLandingProps> {
  model: any
  dealsList: any
  currentLocation: any
  currentCategory: any

  constructor (props: any) {
    super(props)

    this.dealsList = [
      { id: '1', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '2', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '3', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '4', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' }
    ]

    this.model = new VendorsLandingModel({
      locale: props.locale,
      popularVendors: props.popularVendors,
      categories: props.categories,
      locations: props.locations,
      categorySlug: props.categorySlug,
      locationSlug: props.locationSlug,
      faqs: props.faqs,
      vendorsCount: props.vendorsCount
    })
    this.currentLocation = props.currentLocation
    this.currentCategory = props.currentCategory
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorSearchLanding mounted')
    }
  }

  render () {
    return (
            <APIProvider apiKey={this.props.mapsAPIKey}>
                <TranslationProvider user_locale={this.model.locale}>
                    <div className='content d-flex flex-column align-items-center'>
                        <div className="main_title block d-flex justify-content-center">
                            <div className="main_title-text">
                                <h1>
                                    <FormattedMessage
                                        id={`vendors.categories.${this.currentCategory.translation_key}.other`}
                                        defaultMessage=''/>
                                    <span> <FormattedMessage
                                        id={`locations.short_form.${this.currentLocation.translation_key}`}
                                        defaultMessage=''/></span>
                                </h1>
                                <div className="content_line"></div>
                                <p>Легкий поиск и выбор типографий в Астане только на planning.how</p>
                                <VendorLandingForm model={this.model}/>
                            </div>
                            <div className="main_title-img">
                                <img src={MainImg} alt="VendorServicesImg"/>
                            </div>
                        </div>
                        <div className="services_popular mt-5 block">
                            <div className="services_popular-tree">
                                <div><u className='highligted'>Сервисы</u>{' > Типографии > Астана'}</div>
                            </div>
                            <div className="services_popular-title mt-5">
                                <h2 className='block_heading'>Популярные типографии Астаны</h2>
                                <p>Возможность быстро и легко найти именно того вендора, который нужен, с фильтрацией по
                                    важным параметрам</p>
                            </div>
                            <div className="services_popular-list">
                                <PopularServicesList locale={this.model.locale} length={5}
                                                     popularVendors={this.model.popularVendors}
                                                     location={this.currentLocation}
                                                     locationSlug={this.model.locationSlug}
                                                     categorySlug={this.model.categorySlug}
                                                     vendorsCount={this.model.vendorsCount}/>
                            </div>
                        </div>
                        {/* <div className="services_reviews block">
                        <h2 className='block_heading'>Отзывы о Типографиях в Астане</h2>
                        <div className="reviews_list d-flex gap-5 mt-5">
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex gap-1">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex gap-1">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                        </div>
                        <div className="dots d-flex gap-3 align-items-center justify-content-center">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot selected"></div>
                            <div className="dot"></div>
                        </div>
                    </div> */}

                        <div className="services_location block">
                            <h2 className='block_heading'>Местоположение Типографий в Астане</h2>
                            <div className='position-relative mt-5'>
                                <img className='map_img' src={MapImg} alt="Map"/>
                                <div className="vendor_selected">
                                    <PopularServicesItem vendor={this.model.popularVendors[0]}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Map
                                style={{ width: '100vw', height: '100vh' }}
                                defaultCenter={{ lat: 22.54992, lng: 0 }}
                                defaultZoom={3}
                                gestureHandling={'greedy'}
                                disableDefaultUI={true}
                            />
                        </div>
                        {/* <div className="services_offers block">
                        <h2 className='block_heading'>Акции и предложения от типографий Астаны</h2>
                        <div className="services_offers-list">
                            <ServicesDealsList deals={this.dealsList} length={4}></ServicesDealsList>
                        </div>
                    </div> */}
                        <div className="services_faq block">
                            <FormattedMessage
                                id='vendors.landing.faq'
                                defaultMessage='Frequently Asked Questions (FAQ)'
                            >{(text: string) => <h2 className='block_heading'>{text}</h2>}</FormattedMessage>
                            <div className="services_faq-list">
                                {this.model.faqs.map((faq: any) =>
                                  (
                                        <div>
                                            <div className="content_line"></div>
                                            <details className='faq_details'>
                                                <summary>{faq.question}</summary>
                                                {ReactHtmlParser(faq.answer)}
                                            </details>
                                        </div>
                                  ))}
                            </div>
                        </div>
                        <div className="services_answers block">
                            <h2 className='block_heading'>Почему удобно искать типографии в Астане через
                                Planning.how?</h2>
                            <div
                                className="services_answers-list mt-5 d-flex gap-5 justify-content-center align-items-start flex-wrap">
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingSearch} alt="Search"/>
                                    <h2>Удобный поиск</h2>
                                    <p>Фильтры для поиска типографий в Астане помогут вам быстро найти нужное</p>
                                </div>
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingInfo} alt="Information"/>
                                    <h2>Нужная информация</h2>
                                    <p>Карточка каждой типографии содержит всю необходимую информацию для вас</p>
                                </div>
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingChat} alt="Respond"/>
                                    <h2>Быстрая связь</h2>
                                    <p>Свяжитесь с типографией в один клик и получите лучшее предложение для ваших
                                        задач</p>
                                </div>
                            </div>
                        </div>
                        <div className="services_banner block">
                            <div className="services_banner_content w-100">
                                <h2>Хотите разместить типографию из Астаны на planning.how?</h2>
                                <div className="black_button">Разместить услугу</div>
                            </div>
                        </div>
                        <footer></footer>
                    </div>
                </TranslationProvider>
            </APIProvider>
    )
  }
}
