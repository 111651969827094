import { observer } from 'mobx-react'
import React, { FC } from 'react'
import IArticle from '../interfaces/IArticle'

interface IArticleIndexList {
  article: IArticle;
}

const ArticleItem: FC<IArticleIndexList> = observer(({ article }) => {
  return (
    <div className='article__block d-flex d-sm-block' key={article.slug}>
      <div className='article__photo'>
        {/* <img width={'270px'} height={'230px'} src={article.photoUrl} alt="" /> */}
      </div>
      <div className='article__data'>
        <h5><a href={`${article.absolute_url}`}>{article.title}</a></h5>
        {/* {<p>{article.content}</p>} */}
        <div className='content_line d-none d-sm-block'></div>
          {/*
          <div className='d-flex align-items-center justify-content-between mt-3 mt-sm-0'>
          <p>{new Date(article.updated_at).toLocaleDateString('en-US')}</p>
           <button className='share_button'></button>
        </div>
        */}
      </div>
    </div>
  )
})

export default ArticleItem
