import React, { FC } from 'react'
import GuestsInfo from './GuestsInfo'
import TranslationProvider from '../../../providers/TranslationProvider'

interface InfoBlockRootProps {
    starterApp: string,
    locale: string
}
const InfoBlockRoot: FC<InfoBlockRootProps> = ({ starterApp, locale }) => {
  let appInfoBlock = <React.Fragment></React.Fragment>
  if (starterApp == 'guests') {
    appInfoBlock = <GuestsInfo/>
  }
  return (
      <TranslationProvider user_locale={locale}>
        {appInfoBlock}
      </TranslationProvider>
  )
}
export default InfoBlockRoot
