import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import TranslationProvider from '../../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'
import GiftRegistryList from './gift_registry/GiftRegistryList'

export default class EventsAppNew extends React.Component {
  constructor (props) {
    super(props)
    const userInput = props.currentEvent.user_input
    if (userInput) {
      this.name = props.currentEvent.name
      this.dateOfBirth = new Date(userInput.dateOfBirth).toLocaleDateString('en-US')
      this.dateOfDeath = new Date(userInput.dateOfDeath).toLocaleDateString('en-US')

      if (userInput.when) {
        this.when = userInput.when
      }

      this.funeralAddress = props.currentEvent.location
      this.description = userInput.description
      this.additionalTerms = userInput.additionalInfo
    } else {
      this.name = ''
      this.dateOfBirth = ''
      this.dateOfDeath = ''
      this.funeralAddress = ''
      this.when = ''
      this.description = ''
      this.additionalTerms = ''
    }
    this.photoUrl = props.userPhoto
    this.guestsPathTemplate = props.guestsPathTemplate
    this.editEventPathTemplate = props.editEventPathTemplate
    this.isAuthor = props.isAuthor
    this.allGiftRegistries = props.allGiftRegistries

    this.state = {
      readMore: false,
      readMoreText: 'Read more'
    }

    this.locale = props.locale
  }

  readMoreChange = () => {
    this.setState({ readMore: !this.state.readMore })
    if (this.state.readMore) {
      this.setState({ readMoreText: 'Read more' })
    } else {
      this.setState({ readMoreText: 'Read less' })
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
            <div className="content w-100 events__new cover d-flex">
                <div className='head events__new'>
                    <div className='temp__container'>
                        <div className="image__container">
                        {this.photoUrl
                          ? <img src={`${this.photoUrl}`} alt="a photo for your event" />
                          : <div className="empty_img"></div>
                        }
                        </div>
                        <div className="head__info">
                            <h4>{this.name}</h4>
                            {this.dateOfDeath !== 'Invalid Date' && this.dateOfBirth !== 'Invalid Date'
                              && <p>{this.dateOfBirth + ' - ' + this.dateOfDeath}</p>
                            }
                            {this.dateOfDeath === 'Invalid Date' && this.dateOfBirth !== 'Invalid Date'
                              && <p>{this.dateOfBirth}</p>
                            }
                        </div>
                    </div>
                    {this.isAuthor ?
                        <div className="head__edit_btn actions">
                          <button onClick={() => location.href = this.guestsPathTemplate} className="submit mb-3">
                            <FormattedMessage id='spa.manage_guests' defaultMessage='Manage guests'/>
                          </button>
                          <button onClick={() => location.href = this.editEventPathTemplate} className='submit edit'>
                            <FormattedMessage id='spa.edit_event_page' defaultMessage='Edit event page'/>
                          </button>
                        </div>
                      : <div className="d-none"></div>
                    }
                </div>
                <div className="body events__new">
                    {this.when || this.funeralAddress

                      ? // If some date exist
                        <div className="info__block events__new first">
                            <h4>
                                <FormattedMessage id='spa.information' defaultMessage='Information'/>
                            </h4>
                            {this.when &&
                                <div className="date info__block_item row">
                                    <h6 className='col-3'>
                                        <FormattedMessage id='spa.date' defaultMessage='Date'/>:
                                    </h6>
                                    <p className='info__block_text col-9'>{this.when}</p>
                                </div>}
                            {this.funeralAddress &&
                                <div className="address info__block_item row">
                                    <h6 className='col-3'>
                                        <FormattedMessage id='spa.address' defaultMessage='Address'/>:
                                    </h6>
                                    <p className='info__block_text col-9'>{this.funeralAddress}</p>
                                </div>}
                        </div>
                      : <div className="d-none"></div>
                    }

                    {this.description &&
                        <div className="info__block events__new second">
                            <h4>
                                <FormattedMessage id='spa.description' defaultMessage='Description'/>
                            </h4>
                            <div className={`info__block_item ${this.state.readMore}`}>
                                <div>
                                  {ReactHtmlParser(this.description)}
                                </div>
                                {this.description.length > 1000 &&
                                    <p onClick={this.readMoreChange} className={`read__more  ${this.state.readMore}`}>{this.state.readMoreText}</p>
                                }
                            </div>
                        </div>}
                    {this.additionalTerms &&
                        <div className="info__block events__new third">
                            <h4>
                                <FormattedMessage id='spa.additionalInfo' defaultMessage='Additional info'/>
                            </h4>
                            <div className="info__block_item">
                                <p>{this.additionalTerms}</p>
                            </div>
                        </div>}
                    {this.allGiftRegistries.length > 0 &&
                        <div className="info__block events__new third">
                            <h4>
                                <FormattedMessage id='spa.gift_registry' defaultMessage='Gift registry'/>
                            </h4>
                            <div className="info__block_item">
                                <GiftRegistryList allGiftRegistries={this.allGiftRegistries} />
                            </div>
                        </div>}
                </div>
            </div>
            </TranslationProvider>
    )
  }
}
