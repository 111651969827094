import React from 'react'
import { FormattedMessage } from 'react-intl'
import { events_path, new_event_path, root_path } from '../routes.js.erb'
import Logo from './UI/Logo/Logo.tsx'
import LangDropDown from './language/LangDropDown'
import TranslationProvider from './providers/TranslationProvider'

export default class MainHeader extends React.Component {
  constructor (props) {
    super(props)
    this.name = props.name
    this.user = props.user
    this.brand_name = props.brand_name
    this.languages = props.languages
    this.state = {
      dropdownState: false,
      burgerState: false
    }
    this.locale = props.locale
    this.recentEvents = props.recentEvents
    this.logoUrl = root_path({ locale: this.locale })
    this.eventSlug = props.eventSlug
    this.microAppTypes = props.microAppTypes
  }

  handleDropdownState = (newState) => {
    this.setState({ dropdownState: newState })
  }

  handleBurgerState = () => {
    const burgerState = this.state.burgerState
    this.setState({ burgerState: !burgerState })
  }

  render () {
    const { burgerState, dropdownState } = this.state
    if (this.recentEvents.length > 0) {
      this.logoUrl = events_path({ locale: this.locale })
    }

    const renderAdditionalNavigations = (isDesktop = true) => {
      if (!this.eventSlug) return null

      return isDesktop ?
          (
            this.microAppTypes.map((micro_app) => (
                        <nav className='header__nav' key={micro_app.id}>
                            <a href={`/${this.locale}/events/${this.eventSlug}/${micro_app.page_url}`}>
                                <FormattedMessage id={`micro_app_types.${micro_app.translation_key}.name`}
                                                  defaultMessage={micro_app.name}/>
                            </a>
                        </nav>
            ))
          )
        : (
            this.microAppTypes.map((micro_app) => (
                        <a className='header__nav mt-2'
                           href={`/${this.locale}/events/${this.eventSlug}/${micro_app.page_url}`}>
                            <FormattedMessage id={`micro_app_types.${micro_app.translation_key}.name`}
                                              defaultMessage={micro_app.name}/>
                        </a>
            ))
          )
    }

    return (
            <TranslationProvider user_locale={this.locale}>
                <header className="main__header d-flex justify-content-between">
                    <div className="header__logo">
                        <Logo brand_name={this.brand_name} logoUrl={this.logoUrl}/>
                    </div>
                    {this.brand_name !== 'www' && (
                        <div className="header__controll d-flex align-items-center">
                            <div className="header__navs d-flex align-items-center gap-3">
                                {renderAdditionalNavigations()}
                                <div className="dropdown">
                                    <nav onMouseOver={() => this.handleDropdownState(true)} className='header__nav'>
                                        <FormattedMessage id='spa.events' defaultMessage='Events'/>
                                    </nav>
                                    {dropdownState &&
                                        <ul onMouseLeave={() => this.handleDropdownState(false)}
                                            className="dropdown-menu d-block mt-2">
                                            <li>
                                                <a className="dropdown-item" href={events_path(this.locale)}>
                                                    <FormattedMessage id='spa.all_events' defaultMessage='All events'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href={new_event_path(this.locale)}>
                                                    <FormattedMessage id='spa.create_event'
                                                                      defaultMessage='Create new event'/>
                                                </a>
                                            </li>
                                        </ul>}
                                </div>
                            </div>
                            {burgerState === false && (
                                <div className="header__lang d-none d-sm-flex align-items-center">
                                    <LangDropDown locale={this.locale} languages={this.languages}/>
                                </div>
                            )}
                            <div
                                onClick={this.handleBurgerState}
                                className={'header__burger ' + burgerState}
                            ></div>
                            {burgerState === true && (
                                <div>
                                    <div className="header__burger_menu">
                                        <div className="header__burger_menu-head">
                                            <div className="header__lang menu d-flex align-items-center">
                                                <LangDropDown locale={this.locale} languages={this.languages}/>
                                            </div>
                                        </div>
                                        <div className="header__burger_menu-body d-flex align-items-center">
                                            <a className='header__nav mt-2' href={events_path(this.locale)}>
                                                <FormattedMessage id='spa.all_events' defaultMessage='All events'/>
                                            </a>
                                            <a className='header__nav mt-2' href={new_event_path(this.locale)}>
                                                <FormattedMessage id='spa.create_event'
                                                                  defaultMessage='Create new event'/>
                                            </a>
                                            {renderAdditionalNavigations(false)}
                                            <div className="burger__logo">
                                                <div className="container__logo">
                                                    <span>{this.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="header__burger_field"></div>
                                </div>
                            )}
                        </div>
                    )}
                </header>
            </TranslationProvider>
    )
  }
}
