import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import TranslationProvider from '../../providers/TranslationProvider'
import PhoneWithCode from '../../editor/ReactQuill/PhoneWithCode'

import EventServicesImg from '../../../../assets/images/vendors/onboarding/event_services_img.jpg'
import BlueArrow from '../../../../assets/images/vendors/onboarding/blue_arrow.svg'
import ProjectManage from '../../../../assets/images/vendors/onboarding/project_manage.svg'
import UserRating from '../../../../assets/images/vendors/onboarding/user_rating.svg'
import User from '../../../../assets/images/vendors/onboarding/user.jpg'
import LocationsImg from '../../../../assets/images/vendors/main_stock/locations.jpg'
import PhotographsImg from '../../../../assets/images/vendors/main_stock/photographs.jpg'
import CateringImg from '../../../../assets/images/vendors/main_stock/catering.jpg'
import DjsImg from '../../../../assets/images/vendors/main_stock/djs.jpg'
import MusiciansImg from '../../../../assets/images/vendors/main_stock/musicians.jpg'
import TypographyImg from '../../../../assets/images/vendors/main_stock/typography.jpg'
import CakesImg from '../../../../assets/images/vendors/main_stock/cakes.jpg'
import FloristsImg from '../../../../assets/images/vendors/main_stock/florists.jpg'
import Star from '../../../../assets/images/yellow_star.svg'

export default class VendorsOnboardingApp extends Component {
  locale: string

  constructor (props: any) {
    super(props)
    this.locale = props.locale
  }

  render () {
    return (
      <TranslationProvider user_locale={this.locale}>
        <div className='vendor_onboarding d-flex flex-column align-items-center main w-100'>
          <div className="block heading d-flex flex-column flex-md-row justify-content-md-between align-items-center gap-3">
            <div className="block_text">
              <FormattedMessage id='vendors.onboarding.title' defaultMessage='Post event services for free and increase'>
                {(text: string) =>
                  <h1>
                    {text}
                    <br></br>
                    <FormattedMessage id='vendors.onboarding.title_span' defaultMessage='sales up to 35%'/>
                  </h1>
                }
              </FormattedMessage>
              <div className="block_form d-flex flex-column actions">
                <label className='mb-1'>
                  <FormattedMessage id='vendors.onboarding.form_label' defaultMessage='Enter your phone number and create your page'>
                    {(text: string) => text}
                  </FormattedMessage>
                </label>
                <PhoneWithCode
                  countryCode='+1'
                  countryCodeCallback={() => { return true }}
                  phone=''
                  phoneCallback={() => { return true }}
                  clearError={() => { return true }}
                  phoneClassname=''
                  countryCodeClassname='vendor_interest_country_code'
                />
                <button className='submit align-self-end'>
                  <FormattedMessage id='vendors.onboarding.create' defaultMessage='Create'>
                    {(text: string) => text}
                  </FormattedMessage>
                </button>
              </div>
            </div>
            <div className="block_img">
              <img width='650px' height='482px' src={EventServicesImg} alt='Onboarding Image' />
            </div>
          </div>
          <div className="block partnership_benefits w-100">
            <FormattedMessage tagName='h2' id='vendors.onboarding.partnership_benefits.main_title' defaultMessage='Why is it convenient and profitable to work with us?'/>
            <div className="benefits_list mt-3 mt-md-5 d-flex justify-content-between align-items-center flex-column flex-md-row gap-3">
              <div className="benefit_item">
                <div className="header d-flex justify-content-center align-items-center gap-3">
                  <img src={BlueArrow} alt="Trafic Image" />
                  <FormattedMessage tagName='h3' id='vendors.onboarding.partnership_benefits.traffic_title' defaultMessage='Traffic'/>
                </div>
                <div className="description mt-3">
                  <FormattedMessage tagName='p' id='vendors.onboarding.partnership_benefits.mock_desc' defaultMessage='Our algorithms and advertising channels guarantee the growth of client traffic and profits with the help of our tools'/>
                </div>
              </div>
              <div className="benefit_item">
                <div className="header d-flex justify-content-center align-items-center gap-3">
                  <img src={UserRating} alt="Clients Image" />
                  <FormattedMessage tagName='h3' id='vendors.onboarding.partnership_benefits.client_title' defaultMessage='Clients'/>
                </div>
                <div className="description mt-3">
                  <FormattedMessage tagName='p' id='vendors.onboarding.partnership_benefits.mock_desc' defaultMessage='Our algorithms and advertising channels guarantee the growth of client traffic and profits with the help of our tools'/>
                </div>
              </div>
              <div className="benefit_item">
                <div className="header d-flex justify-content-center align-items-center gap-3">
                  <img src={ProjectManage} alt="Management Image" />
                  <FormattedMessage tagName='h3' id='vendors.onboarding.partnership_benefits.manage_title' defaultMessage='Control'/>
                </div>
                <div className="description mt-3">
                  <FormattedMessage tagName='p' id='vendors.onboarding.partnership_benefits.mock_desc' defaultMessage='Our algorithms and advertising channels guarantee the growth of client traffic and profits with the help of our tools'/>
                </div>
              </div>
            </div>
          </div>
          <div className="block onboarding_steps w-100">
            <FormattedMessage tagName='h2' id='vendors.onboarding.steps.how_it_works' defaultMessage='How it works?'/>
            <div className='d-flex mt-5 flex-column align-items-center align-items-md-start flex-md-row walkthrough'>
              <div className='info_step'>
                <div className='d-flex align-items-center justify-content-center mb-3'>
                  <FormattedMessage tagName='h2' id='vendors.onboarding.steps.registration_title' defaultMessage='1. Registration'/>
                </div>
                <FormattedMessage tagName='p' id='vendors.onboarding.steps.registration_desc' defaultMessage='First you need to go through a quick and simple registration using your phone number'/>
              </div>
              <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
              <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
              <div className='info_step'>
                <div className='d-flex align-items-center justify-content-center mb-3'>
                  <FormattedMessage tagName='h2' id='vendors.onboarding.steps.details_title' defaultMessage='2. Details'/>
                </div>
                <FormattedMessage tagName='p' id='vendors.onboarding.steps.details_desc' defaultMessage='Fill out a simple form with your company details and create your page'/>
              </div>
              <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
              <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
              <div className='info_step'>
                <div className='d-flex align-items-center justify-content-center mb-3'>
                  <FormattedMessage tagName='h2' id='vendors.onboarding.steps.traffic_title' defaultMessage='3. Traffic'/>
                </div>
                <FormattedMessage tagName='p' id='vendors.onboarding.steps.traffic_desc' defaultMessage='Ready! Get additional customer traffic and grow your business! our_partners: Who do we collaborate with?'/>
              </div>
            </div>
          </div>
          <div className="block our_partners">
            <FormattedMessage tagName='h2' id='vendors.onboarding.our_partners' defaultMessage='Who do we collaborate with?'/>
            <div className='categories_cards d-flex flex-wrap justify-content-around mt-4'>
              <figure>
                <img alt='Locations' src={LocationsImg} />
                <figcaption>Локации</figcaption>
              </figure>
              <figure>
                <img alt='Photographs' src={PhotographsImg} />
                <figcaption>Фотографы</figcaption>
              </figure>
              <figure>
                <img alt='Catering' src={CateringImg} />
                <figcaption>Кейтеринг</figcaption>
              </figure>
              <figure>
                <img alt='Djs' src={DjsImg} />
                <figcaption>Djs</figcaption>
              </figure>
              <figure>
                <img alt='Musicians' src={MusiciansImg} />
                <figcaption>Музыканты</figcaption>
              </figure>
              <figure>
                <img alt='Typography' src={TypographyImg} />
                <figcaption>Типографии</figcaption>
              </figure>
              <figure>
                <img alt='Cakes' src={CakesImg} />
                <figcaption>Кондитеры</figcaption>
              </figure>
              <figure>
                <img alt='Florists' src={FloristsImg} />
                <figcaption>Флористы</figcaption>
              </figure>
            </div>
          </div>
          <div className="block client_reviews for_brand_color_bg">
            <FormattedMessage tagName='h2' id='vendors.onboarding.reviews.title' defaultMessage='Our clients love us'/>
            <div className="reviews_carousel_container d-flex align-items-center justify-content-center w-100">
              <div className="reviews_carousel">
                <div className="reviews_carousel-list">
                  <div className="reviews_carousel-item_container">
                    <div className="reviews_carousel-item for_brand_color_bg">
                      <img className='user_image' width='85px' height='85px' src={User} alt="Person" />
                      <div className="review_text d-flex flex-column align-items-center">
                        <h3>Алия</h3>
                        <h4>Директор Event Агенства</h4>
                        <div className="reviews_stars d-flex mt-3 mb-5">
                          <img width='16px' height='16px' src={Star} alt="Star" />
                          <img width='16px' height='16px' src={Star} alt="Star" />
                          <img width='16px' height='16px' src={Star} alt="Star" />
                          <img width='16px' height='16px' src={Star} alt="Star" />
                          <img width='16px' height='16px' src={Star} alt="Star" />
                        </div>
                        <p>«Planning how дал нам возможность быстро и легко набрать клиентскую базу и раскуртить наше новое агенстов, особено благодана Даниилу, классый парень»</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination_container w-100 d-flex justify-content-center">
              <div className="reviews_pagination d-flex align-items-center">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot selected"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
          <div className='block all_services'>
            <FormattedMessage tagName='h2' id='vendors.onboarding.all_services.title' defaultMessage='All categories of event services'/>
            <div className='d-flex mt-5 flex-column align-items-center align-items-md-start flex-md-row walkthrough'>
              <div className='d-flex flex-wrap gap-4 w-100 justify-content-center justify-content-md-between'>
                <ul className='d-flex flex-column gap-4'>
                  <li>Фотографы</li>
                  <li>Музыканты</li>
                  <li>Кайтеринг</li>
                  <li>Доставка</li>
                  <li>Типография</li>
                  <li>Djs</li>
                  <li>Декораторы</li>
                </ul>
                <ul className='d-flex flex-column gap-4'>
                  <li>Видеографы</li>
                  <li>Танцевальные уроки</li>
                  <li>Гостиницы</li>
                  <li>Свадебные планеры</li>
                  <li>Доставка</li>
                  <li>Транспорт</li>
                  <li>Ведущие</li>
                </ul>
                <ul className='d-flex flex-column gap-4'>
                  <li>Алкоголь</li>
                  <li>Локации</li>
                  <li>Кондитеры</li>
                  <li>Детские праздники</li>
                  <li>Декораторы</li>
                  <li>Ивент-планеры</li>
                  <li>Видеографы</li>
                </ul>
                <ul className='d-flex flex-column gap-4'>
                  <li>Танцевальные уроки</li>
                  <li>Обрудование</li>
                  <li>Гостиницы</li>
                  <li>Свадебные планеры</li>
                  <li>Доставка</li>
                  <li>Ивент-планеры</li>
                  <li>Фотографы</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </TranslationProvider>
    )
  }
}
