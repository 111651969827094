import React from 'react'
import BudgetModel from './model/BudgetModel'
import BudgetCategory from './BudgetCategory'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

interface IBudgetControll {
  model: BudgetModel
  type: 'income' | 'expense'
}

const BudgetControll: React.FC<IBudgetControll> = observer(({ model, type }) => {
  const categories = model.allCategories[type]
  return (
      <React.Fragment>
        <div className={`budget__controll ${type} p-3 p-sm-4 w-100 mt-4`}>
          <div className='budget__header d-flex justify-content-between align-items-center'>
            <div>
              <h2>
                <FormattedMessage id={type === 'income' ? 'budgets.income' : 'budgets.spending'}
                                  defaultMessage={type === 'income' ? 'Income' : 'Spending'}/>
              </h2>
            </div>
            <div>
              <h1>
                ${categories.overallExpected}
              </h1>
              <h2>
                ${categories.overallActual}
              </h2>
            </div>
          </div>
          <div className='content_line w-100'></div>
          <div className='d-flex flex-column gap-4'>
            {Object.entries(categories.categories).map(([currentID]) => {
              return (
                <BudgetCategory key={currentID} model={model} type={type} currentCategoryId={currentID} />
              )
            })}
          </div>
          <div className='category__add mt-3 mt-sm-4 d-flex justify-content-end actions'>
            <button className='submit d-flex align-items-center'
                    onClick={
                      () => {
                        const categoryData = {
                          name: 'Category',
                          expected: 100,
                          actual: 50,
                          editing: false
                        }
                        model.addCategory(type, categoryData)
                      }
                    }><FormattedMessage id='budgets.add_category' defaultMessage='Add a category'/>
            </button>
          </div>
        </div>
      </React.Fragment>
  )
})

export default BudgetControll
