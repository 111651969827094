import React from 'react'
import { observer } from 'mobx-react'

const PaginationOption = observer(({ option, model }) => {
  return (
        <div onClick={() => model.setCurrentPage(option.id)} className={'events__pages_option' + option.checked} id={option.id}>{option.content}</div>
  )
})

export default PaginationOption
