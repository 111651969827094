import { action, makeObservable, observable } from 'mobx'
import { ILangDropDownModel } from '../types/LangDropDownModel.interface'

interface ILangDropDownModelProps {
  languages: string[]
  selectedLanguage: string
}

class LangDropDownModel implements ILangDropDownModel {
  languages: string[]
  selectedLanguage: string
  isDropdownOpen: boolean

  constructor ({ languages, selectedLanguage }: ILangDropDownModelProps) {
    this.languages = ['en']
    this.selectedLanguage = this.languages[0]
    this.isDropdownOpen = false

    if (languages) {
      this.languages = languages
    }
    if (selectedLanguage) {
      this.selectedLanguage = selectedLanguage
    }

    makeObservable(this, {
      languages: observable,
      selectedLanguage: observable,
      isDropdownOpen: observable,
      setSelectedLanguage: action,
      setIsDropdownOpen: action
    })
  }

  setIsDropdownOpen (isOpen: boolean) {
    this.isDropdownOpen = isOpen
  }

  setSelectedLanguage (language: string) {
    this.selectedLanguage = language

    this.setGlobalLanguage(language)
  }

  private getDividedUrl () {
    const baseUrl = location.origin
    const currentUrl = location.href.replace(baseUrl, '').split('/')

    if (currentUrl[1].length === 2) {
      return currentUrl.slice(2).join('/')
    }

    return currentUrl.slice(1).join('/')
  }

  private setGlobalLanguage (language: string) {
    location.href = `/${language}/${this.getDividedUrl()}`
  }
}

export default LangDropDownModel
