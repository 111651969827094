import { observer } from 'mobx-react'
import React, { FC } from 'react'
import ArticleItem from './ArticleItem'
import { IBrandArticles } from '../interfaces/IBrandArticles'

interface IBrandArticleIndexList {
  brandArticles: IBrandArticles;
}

const ArticlesIndexList: FC<IBrandArticleIndexList> = observer(
  ({ brandArticles }) => {
    return (
      <>
        {Object.keys(brandArticles).map((name) => (
          <div className="d-flex flex-column" key={name}>
            {brandArticles[name].length > 0 && <h3>{name}</h3>}
            <div className="articles__list d-flex justify-content-start gap-4">
              {brandArticles[name].map((article) => (
                <ArticleItem article={article} key={article.slug} />
              ))}
            </div>
          </div>
        ))}
      </>
    )
  }
)

export default ArticlesIndexList
