import React from 'react'
import BudgetModel from './model/BudgetModel'
// import BudgetTotal from './BudgetTotal'
import BudgetControll from './BudgetControll'
import TranslationProvider from '../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

class BudgetApp extends React.Component {
  model: BudgetModel

  constructor (props: any) {
    super(props)
    this.model = new BudgetModel({
      initialBudgetCategories: props.initial_budget_categories || {},
      eventId: props.current_event.id
    })
    this.locale = props.locale
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <div className='mx-auto content budget w-100'>
                    <div className='budget__title mb-4'>
                        <h1><FormattedMessage id='budgets.budgeting' defaultMessage='Budgeting'/></h1>
                        <p className='mt-2'>
                            <FormattedMessage id='budgets.budgeting_note'
                                              defaultMessage='Please read the information about the event to which you have'/>
                        </p>
                    </div>
                    {/* <BudgetTotal model = {this.model} type='total'/> */}
                    <div>
                        <BudgetControll model={this.model} type='expense'/>
                        <BudgetControll model={this.model} type='income'/>
                    </div>
                </div>
            </TranslationProvider>
    )
  }
}

export default BudgetApp
