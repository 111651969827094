import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { fullLanguges } from './objects/fullLanguages.object'
import { ILangDropDownModel } from './types/LangDropDownModel.interface'

interface ILangDropDownSelect {
  model: ILangDropDownModel
}

const LangDropDownSelect: FC<ILangDropDownSelect> = observer(({ model }) => {
  return (
    <React.Fragment>
      <ul onClick={() => model.setIsDropdownOpen(!model.isDropdownOpen)} className={`lang_menu__default ${model.isDropdownOpen}`}>
        <li>
          <div className='lang_menu__option d-flex align-items-center gap-2'>
            <div className='lang_menu__option_icon'></div>
            <span className='lang_menu__option_text'>
              {model.selectedLanguage.toUpperCase()}
            </span>
          </div>
        </li>
      </ul>
      {model.isDropdownOpen &&
        <ul className="lang_menu__select">
          {model.languages.map(language =>
            <li key={language}>
              <div onClick={() => model.setSelectedLanguage(language)} className='lang_menu__option d-flex align-items-center gap-2'>
                <span className={`lang_menu__option_text ${language === model.selectedLanguage && 'active'}`}>
                  {fullLanguges[language]}
                </span>
              </div>
            </li>
          )}
        </ul>
      }
    </React.Fragment>
  )
})

export default LangDropDownSelect
