import React from 'react'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
// import CopyIcon from '../../../assets/images/leads/icons/CopyIcon.svg'
import CloseIcon from '../../../assets/images/leads/icons/CloseIcon.svg'
import DownArrowIcon from '../../../assets/images/leads/icons/DownArrowIcon.svg'
import LeadsPopUp from './LeadsPopUp'
import CircularLoading from '../UI/CircularLoading/CircularLoading'
import Lead from './types/Lead.type'

const LeadsList = observer(({ model }: any) => {
  return (
    <div className='leads_list'>
        {model.isLoading && <CircularLoading />}
        <table className='w-100 desktop_table'>
            <thead className='table_header'>
            <tr>
                <td></td>
                <FormattedMessage tagName='th' id='spa.name' />
                <FormattedMessage tagName='th' id='guests.phone' />
                <FormattedMessage tagName='th' id='guests.comment' />
                <FormattedMessage tagName='th' id='simple_event.date_and_time' />
                <th className='table_controlls'></th>
            </tr>
            </thead>
            <tbody className='table_body'>

            {/* LEADS LIST */}
            { model.leadsList.map((lead: Lead) => (
                <tr className='table_body-row' key={lead.id}>
                <td className='table_number'>{model.leadsList.indexOf(lead) + 1}</td>
                <td>{lead.name}</td>
                <td>
                {lead.phone &&
                <div className='d-flex align-items-center gap-2'>
                    <div>{lead.phone}</div>
                    {/* lead.phone.length > 0 && <button className='copy_button'><img src={CopyIcon} alt='Copy' /></button> */}
                </div>}
                </td>
                { lead.comment && (lead.comment.length > 30
                  ? <td>
                        <p>{lead.comment.slice(0, 31)}...</p>
                        <u onClick={() => model.setIsPopupOpened(true)}>
                            <FormattedMessage id='leads.see_more'>
                                {(text: string) => text}
                            </FormattedMessage>
                        </u>
                        {model.isPopupOpened && <LeadsPopUp notes={lead.comment} model={model} />}
                    </td>
                  : <td>
                        <p>{lead.comment}</p>
                    </td>
                )}
                <td>{lead.createdAt}</td>
                <td className='table_controlls'>
                    {lead.status === model.leadStatuses.new &&
                        <div className='d-flex gap-2'>
                            <button className='submit' onClick={() => model.serverStatusUpdate(lead, model.leadStatuses.won)}>
                            <img src={DownArrowIcon} alt='Submit'/>
                            </button>
                            <button className='delete' onClick={() => model.serverStatusUpdate(lead, model.leadStatuses.lost)}>
                            <img src={CloseIcon} alt='Delete'/>
                            </button>
                        </div>
                    }
                    {lead.status === model.leadStatuses.won &&
                        <div className='d-flex justify-content-center'>
                            <span className='won'>Одобренно</span>
                        </div>
                    }
                    {lead.status === model.leadStatuses.lost &&
                        <div className='d-flex justify-content-center'>
                            <span className='lost'>Отменено</span>
                        </div>
                    }
                </td>
                </tr>
            ))}
            </tbody>
        </table>

        {/* LEADS MOBILE */}
        <table className='w-100 mobile_table table_body'>
            { model.leadsList.map((lead: Lead) => (
                <tbody className='table_body-row' key={lead.id}>
                    <tr>
                        <td className='table_number'>{model.leadsList.indexOf(lead) + 1}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='spa.name' />
                        <td>{lead.name}</td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='guests.phone' />
                        <td>
                            {lead.phone &&
                                <div className='d-flex align-items-center gap-2'>
                                <div>{lead.phone}</div>
                                {/* lead.phone.length > 0 && <button className='copy_button'><img src={CopyIcon} alt='Copy' /></button> */}
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='guests.comment' />
                        {lead.comment && (lead.comment.length > 30
                          ? <td>
                                <p>{lead.comment.slice(0, 31)}...</p>
                                <u onClick={() => model.setIsPopupOpened(true)}>
                                    <FormattedMessage id='leads.see_more'>
                                        {(text: string) => text}
                                    </FormattedMessage>
                                </u>
                                {model.isPopupOpened && <LeadsPopUp notes={lead.comment} model={model} />}
                            </td>
                          : <td>
                                <p>{lead.comment}</p>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='simple_event.date_and_time' />
                        <td>{lead.createdAt}</td>
                    </tr>
                    <tr>
                        <td className='table_controlls'>
                            {lead.status === model.leadStatuses.new &&
                                <div className='d-flex gap-2'>
                                    <button className='submit' onClick={() => model.serverStatusUpdate(lead, model.leadStatuses.won)}>
                                    <img src={DownArrowIcon} alt='Submit'/>
                                    </button>
                                    <button className='delete' onClick={() => model.serverStatusUpdate(lead, model.leadStatuses.lost)}>
                                    <img src={CloseIcon} alt='Delete'/>
                                    </button>
                                </div>
                            }
                            {lead.status === model.leadStatuses.won &&
                                <div className='d-flex justify-content-center'>
                                    <span className='won'>Одобренно</span>
                                </div>
                            }
                            {lead.status === model.leadStatuses.lost &&
                                <div className='d-flex justify-content-center'>
                                    <span className='lost'>Отменено</span>
                                </div>
                            }
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            )) }
        </table>
    </div>
  )
})

export default LeadsList
