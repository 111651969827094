import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const GiftRegistryEdit = ({ model, intl }) => {
  const nameChange = (value: string) => {
    model.setTempRegistryName(value)
  }

  const urlChange = (value: string) => {
    model.setTempRegistryURL(value)
  }

  const cancelEditing = () => {
    model.resetTempRegistry()
    model.setRegistryEditing(false)
  }

  const saveHandler = () => {
    model.updateGiftRegistryOnServer(model.tempRegistry)
    cancelEditing()
  }

  return (
    <React.Fragment>
        <div className='gift_registry-edit p-3 position-absolute'>
            <div className='row gap-2 gap-sm-0 row-cols-1 row-cols-sm-3'>
                <div className='col col-12 col-sm-6'>
                    <h6 className='mb-2'><FormattedMessage id="micro_app_types.gift_registry.registry_name" defaultMessage="Registry name" /></h6>
                    <input type="text"
                           name="name"
                           placeholder={
                            intl.formatMessage({
                              id: 'micro_app_types.gift_registry.name_title',
                              defaultMessage: 'Name'
                            })
                           }
                           defaultValue={model.tempRegistry.name}
                           onChange={(event) => nameChange(event.target.value)}
                    />
                    <h6 className='mb-2'><FormattedMessage id="micro_app_types.gift_registry.registry_url" defaultMessage="Registry url" /></h6>
                    <input type="text"
                           name="url"
                           placeholder={
                            intl.formatMessage({
                              id: 'micro_app_types.gift_registry.url',
                              defaultMessage: 'URL'
                            })
                           }
                           defaultValue={model.tempRegistry.external_url}
                           onChange={(event) => urlChange(event.target.value)}
                    />
                </div>
            </div>
            <div className='mh-5 d-flex justify-content-between align-items-center mt-3'>
                <div className='actions d-flex gap-2'>
                    <button type="button"
                            className='cancel_btn'
                            onClick={cancelEditing}>
                        <FormattedMessage id='spa.cancel' defaultMessage='Cancel'/>
                    </button>
                    <button className='submit'
                            onClick={saveHandler}>
                        <FormattedMessage id='spa.save' defaultMessage='Save'/>
                    </button>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default injectIntl(GiftRegistryEdit)
