import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { FormattedMessage, injectIntl } from 'react-intl'
import EventSimpleNewModel from '../model/EventSimpleNewModel'

interface WhenWhereFormContentProps {
    model: EventSimpleNewModel,
    intl: any
}

const WhenWhereFormContent: FC<WhenWhereFormContentProps> = observer(({ model, intl }) => {
  return (
        <React.Fragment>
            <div className='simple_new title mb-4 mb-md-5'>
                <h3>
                    <FormattedMessage id="simple_event.where_and_when" defaultMessage="Where and when"/>
                </h3>
                <h3>
                  <span className='title_smaller'>
                    <FormattedMessage id="simple_event.date_and_time_title"
                                      defaultMessage="Are you planning your event?"/>
                  </span>
                </h3>
            </div>
            <div className='simple_new form mb-3'>
                <label className='simple_new label mb-1' htmlFor="event_address_form">
                    <FormattedMessage id="simple_event.address_for_guests" defaultMessage="Guest address"/>
                </label>
                <input
                    className={model.isInputEmpty
                      ? 'simple_new input mb-3 mb-md-4 error'
                      : 'simple_new input mb-3 mb-md-4'
                    }
                    placeholder={intl.formatMessage({
                      id: 'simple_event.address_placeholder',
                      defaultMessage: 'Write your address on the invitation'
                    })}
                    type='text'
                    name="event_address_form"
                    id="event_address_form"
                    value={model.where}
                    onClick={() => model.setIsInputEmpty(false)}
                    onChange={(e) => {
                      model.setWhere(e.target.value)
                    }}
                />
                <label className='simple_new label mb-1' htmlFor="event_date_form">
                    <FormattedMessage id="simple_event.date_and_time" defaultMessage="Date and time"/>
                </label>
                <input
                    className={model.isInputEmpty
                      ? 'simple_new input error'
                      : 'simple_new input'
                    }
                    placeholder={intl.formatMessage({
                      id: 'simple_event.date_placeholder',
                      defaultMessage: 'July 29, 2024, 18:30'
                    })}
                    type='text'
                    name="event_date_form"
                    id="event_date_form"
                    value={model.when}
                    onClick={() => model.setIsInputEmpty(false)}
                    onChange={(e) => {
                      model.setWhen(e.target.value)
                    }}
                />
            </div>
            <div className='simple_new actions'>
                <button className='main_submit' onClick={() => {
                  model.setStepNumber(3)
                }}>
                    <FormattedMessage id="simple_event.next" defaultMessage="Further"/>
                </button>
            </div>
        </React.Fragment>
  )
})

interface WhenWhereFormProps {
    model: EventSimpleNewModel
}
class WhenWhereForm extends React.Component {
  model: EventSimpleNewModel
  whenWhereWithIntl: any

  constructor (props: WhenWhereFormProps) {
    super(props)
    this.model = props.model
    this.whenWhereWithIntl = injectIntl(WhenWhereFormContent)
  }

  componentDidMount (): void {
    if (ahoy) {
      ahoy.track('WhenWhereForm mounted')
    }
    if (fbq) {
      fbq('track', 'ViewContent', {
        content_name: 'WhenWhereForm mounted'
      })
    }
    if (gtag) {
      gtag('event', 'ViewContent', {
        content_name: 'WhenWhereForm mounted'
      })
    }
  }

  render () {
    const WhenWhereWithIntl = this.whenWhereWithIntl
    return <WhenWhereWithIntl model={this.model}/>
  }
}

export default WhenWhereForm
