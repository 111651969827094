import React from 'react'
import { observer } from 'mobx-react'
import PaginationOption from './PaginationOption'
import PaginationArrow from './PaginationArrow'

const Pagination = observer(({ model }) => {
  if (!model.totalPages || model.totalPages <= 1) {
    return null
  } else {
    const paginationOptions = []
    for (let i = 1; i <= model.totalPages; i++) {
      paginationOptions.push(i)
    }

    return (
        <div className='w-100'>
        <div className="events__page_options d-flex">
            <PaginationArrow arrow={{ side: ' left', active: model.currentPage === 1 ? ' nonactive' : ' active' }} model={model} ></PaginationArrow>
            {paginationOptions.map(page =>
              <PaginationOption key={page} option={{ id: page, content: page, checked: model.currentPage === page ? ' checked' : '' }} model={model} />
            )}
            <PaginationArrow arrow={{ side: ' right', active: model.currentPage === model.totalPages ? ' nonactive' : ' active' }} model={model}></PaginationArrow>
        </div>
        </div>
    )
  }
})

export default Pagination
