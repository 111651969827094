import React, { Component } from 'react'
import IArticle from './interfaces/IArticle'
import IArticlesIndexModel from './interfaces/IArticlesIndexModel'
import IBrands from './interfaces/IBrands'
import ArticlesIndexList from './list/ArticlesIndexList'
import ArticlesIndexModel from './models/ArticlesIndexModel'

interface IArticlesIndex {
  articles: IArticle[];
  brands: IBrands[];
}

class ArticlesIndex extends Component<IArticlesIndex> {
  model: IArticlesIndexModel

  constructor (props: IArticlesIndex) {
    super(props)

    this.model = new ArticlesIndexModel({
      articles: this.props.articles,
      brands: this.props.brands
    })
  }

  render () {
    return (
      <div>
        <div className="header">
          <h2>Articles</h2>
        </div>
        <div className="d-flex flex-column justify-content-center gap-4">
          {this.model.sortedArticles.length > 0
            ? <ArticlesIndexList brandArticles={this.model.brandArticles} />
            : <h2>Nothing yet...</h2>
          }
        </div>
      </div>
    )
  }
}

export default ArticlesIndex
