import React, { FC } from 'react'
import { edit_event_path } from '../../routes.js.erb'
import { FormattedMessage } from 'react-intl'
interface IGuestEventInfo {
  currentEvent: any
  userPhoto: string
}

const GuestEventInfo: FC<IGuestEventInfo> = (
  { currentEvent, userPhoto }
) => {
  return (
    <React.Fragment>
    <div className='guest_event-card p-4 mb-4 w-100'>
      <div className='d-flex gap-4'>
        <div className='guest_event-img'>
          <img width={130} height={130} src={userPhoto} alt="Event image" />
        </div>
        <div className='guest_event-info'>
          <h3>{currentEvent.name}</h3>
          <div>
            <p className='address d-flex align-items-center mt-3'>{
              currentEvent.location
                ? currentEvent.location
                : <FormattedMessage id='spa.location' defaultMessage='Location' />
            }</p>
            <p className='date d-flex align-items-center mt-3'>{
              currentEvent.when
                ? currentEvent.when
                : <FormattedMessage id='spa.date' defaultMessage='Date' />
            }</p>
          </div>
        </div>
      </div>
      <div className='edit_button d-flex justify-content-center align-items-center'>
        <a href={edit_event_path(LOCALE, currentEvent.id)} className='events__item_edit' />
      </div>
    </div>
    <div className='guest_event-card mobile mb-4 w-100'>
      <div className='guest_event-img d-flex justify-content-center'>
        <div>
          <img width={130} height={130} src={userPhoto} alt="Event image" />
          <h3 className='mt-2'>{currentEvent.name}</h3>
        </div>
      </div>
      <div className='guest_event-info'>
        <div>
          <p className='address d-flex align-items-center'>{currentEvent.location}</p>
          <p className='date d-flex align-items-center mt-2'>{currentEvent.when}</p>
        </div>
      </div>
      <div className='edit_button d-flex justify-content-center align-items-center'>
        <a href={edit_event_path(LOCALE, currentEvent.id)} className='events__item_edit' />
      </div>
    </div>
    </React.Fragment>
  )
}

export default GuestEventInfo
