import React from 'react'
import { FormattedMessage } from 'react-intl'

const InitialToDoHeader = () => {
  return (
        <div className='head_all d-flex'>
            <div className="mt-2">
                <div className="col-xxl-8 description">
                    <div className="row">
                        <div className="col-10">
                            <h5 className="lead">
                                <FormattedMessage id='spa.checklist_description'
                                                  defaultMessage='Easy and simple checklist, that would help you plan your event from start to finish.'/>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
export default InitialToDoHeader
